import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useToggleState from 'hooks/useToggleState';
import { useTranslation } from 'i18n';
import { EditOutlined } from '@ant-design/icons';
import Modal from 'components/Modal';
import cx from 'classnames';
import usePermissions from 'hooks/usePermissions';
import {
  PERMISSION_APPLICATION_MANAGE,
  PERMISSION_USERS_MANAGE,
} from 'consts/permissions';
import useCurrentUser from 'hooks/useCurrentUser';
import EditCommentForm from './EditCommentForm';

function EditCommentModalButton({
  applicationPk,
  commentPk,
  initialContent,
  styles,
  createdBy,
}) {
  const { t } = useTranslation();
  const [isModalVisible, showModal, hideModal] = useToggleState(false);
  const [
    canManageOwn,
    canManageOthers,
    { showNoPermissionMessage, arePermissionsLoading },
  ] = usePermissions([PERMISSION_APPLICATION_MANAGE, PERMISSION_USERS_MANAGE]);
  const [currentUser, isCurrentUserLoading] = useCurrentUser();

  const handleClick = useCallback(() => {
    // allow editing either if it's user's comment or they have permission to manage other users' comments
    if (currentUser.pk === createdBy.pk || canManageOthers) {
      return showModal();
    }

    showNoPermissionMessage();
  }, [
    currentUser,
    canManageOthers,
    showModal,
    showNoPermissionMessage,
    createdBy,
  ]);

  if (!canManageOwn || isCurrentUserLoading || arePermissionsLoading)
    return null;

  return (
    <>
      <Modal
        title={t('Activity-editCommentModal--title')}
        open={isModalVisible}
        footer={null}
        onCancel={hideModal}
        destroyOnClose
      >
        <EditCommentForm
          applicationPk={applicationPk}
          commentPk={commentPk}
          initialContent={initialContent}
          onCancel={hideModal}
        />
      </Modal>
      <span>
        <EditOutlined
          className={cx(styles.iconEdit)}
          onClick={handleClick}
          data-testid="edit-icon"
        />
      </span>
    </>
  );
}

EditCommentModalButton.propTypes = {
  applicationPk: PropTypes.string.isRequired,
  commentPk: PropTypes.string.isRequired,
  initialContent: PropTypes.object,
  styles: PropTypes.object,
  createdBy: PropTypes.object.isRequired,
};

export default EditCommentModalButton;
