import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cx from 'classnames';

import { FIELD_TYPE_TEXT, FIELD_TYPE_MULTICHOICE } from 'consts/fieldTypes';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Tag from 'components/Tag';
import useToggleState from 'hooks/useToggleState';
import getColorFromString from 'utils/getColorFromString';
import useCustomFieldValueFill from './useCustomFieldValueFill';
import useCustomFieldValuesOverride from './useCustomFieldValuesOverride';
import useCustomFieldValueDelete from './useCustomFieldValueDelete';
import useCustomFieldValueDeselect from './useCustomFieldValueDeselect';
import OutsideClickHandler from './OutsideClickHandler';

import styles from './CustomFieldTag.less';

const showRemoveButton = (onRemove, isSaving) => (
  <button
    disabled={isSaving}
    className={styles.buttonDelete}
    onClick={onRemove}
    type="button"
    data-testid="CustomFieldTag_button-deleteTag"
    aria-label="Delete"
  >
    <DeleteOutlined />
  </button>
);

/**
 * Component for displaying Custom Field tag.
 * Depending of existence of initialValue property, proper kind of tag is rendered:
 * "add value" empty tag, or filled tag showing CF value.
 */

function CustomFieldTag({
  pk,
  name,
  initialValue,
  className,
  applicationPk,
  candidatePk,
  choices,
  fieldType,
  valuePk,
  hasApplicationDetailsManagePermission,
}) {
  const customColor = getColorFromString(name);
  const [isInputVisible, onInputOpen, onInputClose] = useToggleState(false);
  const [customFieldValue, setCustomFieldValue] = useState(initialValue);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [customFieldValues, setCustomFieldValues] = useState();
  const [
    isMultichoiceTagFocused,
    onMultichoiceTagFocus,
    onMultichoiceTagUnfocus,
  ] = useToggleState(false);
  const selectWrapper = useRef(null);

  const { onDelete } = useCustomFieldValueDelete({
    customFieldPk: pk,
    applicationPk,
    candidatePk,
  });

  const { onDeselect } = useCustomFieldValueDeselect({
    customChoicePk: valuePk,
    applicationPk,
    candidatePk,
  });

  const onDone = useCallback(() => {
    setTooltipVisible(false);
    onInputClose();
    onMultichoiceTagUnfocus();
  }, [onInputClose, onMultichoiceTagUnfocus]);

  const sendCustomFieldValues =
    fieldType === FIELD_TYPE_TEXT
      ? useCustomFieldValueFill
      : useCustomFieldValuesOverride;

  const { onSubmit, fieldErrors, clearFieldError, isSaving } =
    sendCustomFieldValues({
      customFieldPk: pk,
      applicationPk,
      candidatePk,
      onDone,
    });

  const onSave = useCallback(
    () =>
      onSubmit({
        customFieldValue,
        customFieldValues,
        initialValue,
        setCustomFieldValues,
      }),
    [onSubmit, customFieldValue, customFieldValues, initialValue],
  );

  /* Function enables operating multiselect by keyboard. On enter - select or deselect option (by default),
  on Space - save all choices and close select dropdown, on Escape - close dropdown with no action */
  const onInputKeyDown = (e) => {
    // close select on escape
    if (e.key === 'Escape') {
      onDone();
      // save all choices in multiselect on Space press
    } else if (e.key === ' ') {
      e.preventDefault();
      onSave();
    }
  };

  const onInputChange = useCallback(
    (e) => {
      clearFieldError('value');
      setCustomFieldValue(e.target.value);
    },
    [setCustomFieldValue, clearFieldError],
  );

  const onInputPressEnter = useCallback(
    (e) => {
      if (e.target.value) {
        onSave();
      } else {
        onDelete();
        onDone();
      }
    },
    [onDone, onSave, onDelete],
  );

  const onSelectChange = useCallback(
    (values) => {
      clearFieldError('values');
      setCustomFieldValues(values);
    },
    [setCustomFieldValues, clearFieldError],
  );

  const onTooltipVisibleChange = useCallback(
    (visible) => setTooltipVisible(visible),
    [setTooltipVisible],
  );

  const onSelectClick = useCallback(() => {
    clearFieldError('values');
  }, [clearFieldError]);

  const onOutsideClick = useCallback(() => {
    if (customFieldValue || customFieldValues?.length > 0) {
      onSave();
    } else {
      if (initialValue) {
        onDelete();
      }
      onDone();
    }
  }, [
    onDone,
    onSave,
    onDelete,
    initialValue,
    customFieldValue,
    customFieldValues,
  ]);

  const onMultichoiceTagClick = useCallback(() => {
    if (!hasApplicationDetailsManagePermission) return;

    if (isMultichoiceTagFocused) {
      onMultichoiceTagUnfocus();
    } else {
      onMultichoiceTagFocus();
    }
  }, [
    hasApplicationDetailsManagePermission,
    isMultichoiceTagFocused,
    onMultichoiceTagFocus,
    onMultichoiceTagUnfocus,
  ]);

  return (
    /**
     * Display Tooltip with Custom Field name for:
     * - tags with Custom Field Values
     * - opened inputs
     */
    <Tooltip
      title={(initialValue || isInputVisible) && name}
      trigger={isInputVisible ? 'focus' : 'hover'}
      open={isTooltipVisible}
      onOpenChange={onTooltipVisibleChange}
    >
      {
        /**
         * When tag is clicked (isInputVisible=true) display input and hide tag.
         * When input is closed, display tag.
         */
        isInputVisible && (
          <Field
            error={fieldErrors.value || fieldErrors?.values}
            className={styles.field}
          >
            <OutsideClickHandler onOutsideClick={onOutsideClick}>
              {fieldType === FIELD_TYPE_MULTICHOICE ? (
                <div ref={selectWrapper}>
                  <Select
                    id="select"
                    className={styles.select}
                    data-testid={`CustomFieldTag-select-${name
                      .toLowerCase()
                      .replace(' ', '-')}`}
                    mode="multiple"
                    size="small"
                    showArrow
                    autoFocus
                    // When dropdown is open, it covers fieldErrors, so we need to close it when fieldError appears
                    open={!fieldErrors?.values}
                    // To open dropdown when fieldErrors appear, we need to clearFieldError on input click
                    onClick={onSelectClick}
                    allowClear
                    disabled={isSaving}
                    onInputKeyDown={onInputKeyDown}
                    onChange={onSelectChange}
                    choices={choices?.map((choice) => ({
                      value: choice.value,
                      label: choice.value,
                    }))}
                    /**
                     * getPopupContainer sets parent node which the selector should be rendered to.
                     * We use it to render container with options inside select wrapper
                     * so onOutsideClick isn't triggered when user clicks on Select.
                     */
                    getPopupContainer={() => selectWrapper.current}
                  />
                </div>
              ) : (
                <Input
                  type="text"
                  size="small"
                  value={customFieldValue}
                  onChange={onInputChange}
                  onPressEnter={onInputPressEnter}
                  autoFocus
                  className={styles.input}
                  disabled={isSaving}
                />
              )}
              {
                /** Show delete icon only with existing CF values. */
                initialValue && showRemoveButton(onDelete, isSaving)
              }
            </OutsideClickHandler>
          </Field>
        )
      }
      {!isInputVisible &&
        initialValue &&
        fieldType === FIELD_TYPE_MULTICHOICE && (
          <span
            className={cx(
              isMultichoiceTagFocused && styles.customChoiceTagWrapperFocused,
            )}
          >
            <Tag
              color={`#${customColor.color}`}
              style={{ color: `#${customColor.textColor}` }}
              className={cx(
                className,
                isMultichoiceTagFocused && styles.customChoiceTagFocused,
              )}
              data-testid="CustomFieldTag-multi-choice"
              onClick={onMultichoiceTagClick}
            >
              <span>{initialValue}</span>
            </Tag>
            {isMultichoiceTagFocused && showRemoveButton(onDeselect, isSaving)}
          </span>
        )}
      {!isInputVisible && initialValue && fieldType === FIELD_TYPE_TEXT && (
        /**
         * If Custom Field Value is provided display tag with orignal Value.
         * Otherwise display empty +add tag, with CF name only
         */
        <Tag
          color={`#${customColor.color}`}
          style={{ color: `#${customColor.textColor}` }}
          className={className}
          onClick={hasApplicationDetailsManagePermission ? onInputOpen : null}
          data-testid="CustomFieldTag-text"
        >
          <span>{initialValue}</span>
        </Tag>
      )}
      {!isInputVisible && !initialValue && (
        <Tag
          onClick={hasApplicationDetailsManagePermission ? onInputOpen : null}
          className={className}
        >
          <PlusOutlined className={styles.icon} /> {name}
        </Tag>
      )}
    </Tooltip>
  );
}

CustomFieldTag.propTypes = {
  pk: PropTypes.string,
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  className: PropTypes.string,
  applicationPk: PropTypes.string,
  candidatePk: PropTypes.string,
  choices: PropTypes.array,
  fieldType: PropTypes.string,
  valuePk: PropTypes.string,
  hasApplicationDetailsManagePermission: PropTypes.bool,
};

export default CustomFieldTag;
