import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Button from 'components/Form/Button';
import Select from 'components/Form/Select';

import { useMutation, gql } from '@apollo/client';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { showError } from 'utils/message';
import { roleName } from 'utils/translation';

import cx from 'classnames';
import styles from './index.less';

const CHANGE_USER_ROLE_MUTATION = gql`
  mutation ChangeUserRoleMutation(
    $membershipPk: String!
    $rolePk: String!
    $organizationPk: String!
  ) {
    changeMembershipDetails(
      membershipPk: $membershipPk
      rolePk: $rolePk
      organizationPk: $organizationPk
    ) {
      errors {
        fields
        message
      }
      membership {
        id
        role {
          id
          name
        }
      }
    }
  }
`;

const VALIDATE_FIELDS = {
  role: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

function ChangeRoleButton({ memberData, roles, t }) {
  const [isVisible, setIsVisible] = useState(false);
  const [rolePk, setRolePk] = useState(undefined);
  const [changeRole] = useMutation(CHANGE_USER_ROLE_MUTATION);

  const {
    mutate: changeUserRole,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
    isSaving,
  } = useErrorFieldsMutation(changeRole);

  const onSubmit = useCallback(() => {
    const validationErrors = getValidationErrors({ role: rolePk });

    if (!validationErrors) {
      changeUserRole({
        variables: {
          membershipPk: memberData.pk,
          organizationPk: memberData.organization.pk,
          rolePk,
        },
      })
        .then(({ data, ok }) => {
          if (ok) {
            message.info(t('ChangeRoleButton_mutation-succesful'));
            setIsVisible(!isVisible);
          } else if (data.changeMembershipDetails.errors) {
            return data.changeMembershipDetails.errors.message
              ? showError(data.changeMembershipDetails.errors.message)
              : logAndShowGenericError(
                  'changeUserRole data validation error occurred',
                  {
                    data,
                    membershipPk: memberData.pk,
                    organizationPk: memberData.organization.pk,
                    rolePk,
                  },
                )();
          } else {
            logAndShowGenericError('changeUserRole error occurred', {
              data,
              membershipPk: memberData.pk,
              organizationPk: memberData.organization.pk,
              rolePk,
            })();
          }
        })
        .catch(
          logAndShowGenericError('changeUserRole rejected', {
            membershipPk: memberData.pk,
            organizationPk: memberData.organization.pk,
            rolePk,
          }),
        );
    } else {
      setFieldErrors(validationErrors);
    }
  }, [
    memberData.organization.pk,
    memberData.pk,
    rolePk,
    changeUserRole,
    setFieldErrors,
    isVisible,
    t,
  ]);

  const onEditVisibilityToggle = () => setIsVisible(!isVisible);

  const onSelect = (value) => {
    setRolePk(value);
    clearFieldError('role');
  };

  return (
    <div>
      <Button
        iconOnly
        icon={
          <EditOutlined
            className={cx(styles.icon, styles.iconEdit)}
            data-testid="change-role-button"
            aria-hidden
          />
        }
        onClick={onEditVisibilityToggle}
        aria-label="change-role"
      />
      <Modal
        open={isVisible}
        title={t('ChangeRoleButton_editUserRole')}
        onCancel={onEditVisibilityToggle}
        footer={null}
      >
        {isVisible ? (
          <Form onFinish={onSubmit} layout="inline">
            <Field className={styles.field} error={fieldErrors.role}>
              <div>
                <Select
                  data-testid="roleSelect"
                  className={styles.roleSelect}
                  placeholder={t('changeRoleSelect')}
                  onChange={onSelect}
                  choices={roles.map(({ id, name }) => ({
                    value: id,
                    label: roleName(t, name),
                  }))}
                  value={rolePk}
                  aria-label={t('changeRoleSelect')}
                />
              </div>
            </Field>
            <Field className={styles.field}>
              <Button
                size="default"
                loading={isSaving}
                type="primary"
                htmlType="submit"
                data-testid="submit"
                data-role="change-role-submit-button"
              >
                {t('changeRoleSubmit')}
              </Button>
            </Field>
          </Form>
        ) : null}
      </Modal>
    </div>
  );
}

ChangeRoleButton.propTypes = {
  memberData: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default ChangeRoleButton;
