import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';

import { useEmailPreferences } from 'providers/EmailPreferencesProvider';
import emailTemplatesListQuery from 'pages/settings/email-templates/emailTemplatesListQuery.gql';

import applicationDataForMagicTagsQuery from './applicationDataForMagicTagsQuery.gql';

import TemplateSelect from './TemplateSelect';

export const MANAGE_TEMPLATE = 'MANAGE_TEMPLATE';

const MAGIC_TAGS = [
  ['jobPosition', 'job_position_name'],
  ['firstName', 'first_name'],
  ['lastName', 'last_name'],
  ['organizationName', 'organization_name'],
];

const MAGIC_TAGS_WITH_REGEX = MAGIC_TAGS.map(([name, value]) => [
  name,
  new RegExp(`{{${value}}}`, 'g'),
]);

function TemplateSelectContainer({
  emailTemplatesList,
  onSelectEmailTemplate,
  organizationName,
  applicationDataForMagicTags,
  isEmailTemplatesListLoading,
  isApplicationDataForMagicTagsLoading,
  defaultTemplate,
  reasonPk,
}) {
  // Always call the hook, but handle missing context case
  const emailPreferences = useEmailPreferences();
  const hasEmailPreferences = Boolean(emailPreferences);

  const isDataLoading = useMemo(
    () => isEmailTemplatesListLoading || isApplicationDataForMagicTagsLoading,
    [isEmailTemplatesListLoading, isApplicationDataForMagicTagsLoading],
  );

  // Get remembered template if in rejection flow
  const rememberedTemplate = useMemo(() => {
    if (
      reasonPk &&
      hasEmailPreferences &&
      emailPreferences.getRejectionTemplate
    ) {
      return emailPreferences.getRejectionTemplate(reasonPk);
    }
    return null;
  }, [reasonPk, hasEmailPreferences, emailPreferences]);

  // Use remembered template or provided default
  const effectiveDefaultTemplate = useMemo(
    () => rememberedTemplate || defaultTemplate,
    [rememberedTemplate, defaultTemplate],
  );

  const replaceMagicTags = useCallback(
    (string = '') => {
      if (
        !applicationDataForMagicTags?.jobPosition?.position ||
        !applicationDataForMagicTags?.candidate
      ) {
        return string;
      }

      const {
        jobPosition: { position },
        candidate: { firstName, lastName },
      } = applicationDataForMagicTags;

      const data = {
        jobPosition: position,
        firstName,
        lastName,
        organizationName,
      };

      return MAGIC_TAGS_WITH_REGEX.reduce(
        (acc, [name, regex]) => acc.replace(regex, data[name]),
        string,
      );
    },
    [applicationDataForMagicTags, organizationName],
  );

  // If we have a default/remembered template and all data is loaded,
  // automatically run substitution and inform the parent if onSelectEmailTemplate is provided.
  useEffect(() => {
    if (effectiveDefaultTemplate && !isDataLoading && onSelectEmailTemplate) {
      const { title = '', content = '', pk } = effectiveDefaultTemplate;
      const replacedTitle = replaceMagicTags(title);
      const replacedContent = replaceMagicTags(content);
      onSelectEmailTemplate(replacedTitle, replacedContent, pk);
    }
  }, [
    effectiveDefaultTemplate,
    isDataLoading,
    onSelectEmailTemplate,
    replaceMagicTags,
  ]);

  const handleTemplateSelect = useCallback(
    (title, content, pk) => {
      if (typeof onSelectEmailTemplate === 'function') {
        const titleReplaced = replaceMagicTags(title);
        const contentReplaced = replaceMagicTags(content);
        onSelectEmailTemplate(titleReplaced, contentReplaced, pk);

        // Save template choice if in rejection flow and context is available
        if (
          reasonPk &&
          hasEmailPreferences &&
          emailPreferences.setRejectionTemplate
        ) {
          emailPreferences.setRejectionTemplate(reasonPk, pk);
        }
      }
    },
    [
      replaceMagicTags,
      onSelectEmailTemplate,
      reasonPk,
      hasEmailPreferences,
      emailPreferences,
    ],
  );

  const containerRef = useRef();

  return (
    <div ref={containerRef}>
      <TemplateSelect
        emailTemplatesList={emailTemplatesList}
        onSelectEmailTemplate={handleTemplateSelect}
        isDataLoading={isDataLoading}
        popupContainer={containerRef}
        defaultTemplate={effectiveDefaultTemplate}
      />
    </div>
  );
}

TemplateSelectContainer.propTypes = {
  emailTemplatesList: PropTypes.array,
  onSelectEmailTemplate: PropTypes.func,
  organizationName: PropTypes.string,
  applicationDataForMagicTags: PropTypes.object,
  isEmailTemplatesListLoading: PropTypes.bool,
  isApplicationDataForMagicTagsLoading: PropTypes.bool,
  defaultTemplate: PropTypes.shape({
    pk: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  reasonPk: PropTypes.string,
};

export default compose(
  withOrganizationPk,
  graphql(emailTemplatesListQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      emailTemplatesList: !loading && viewer ? viewer.emailTemplates.edges : [],
      isEmailTemplatesListLoading: loading,
    }),
  }),
  graphql(applicationDataForMagicTagsQuery, {
    options: ({ organizationPk, applicationPk }) => ({
      variables: { organizationPk, applicationPk },
    }),
    props: ({ data: { viewer, loading } }) => ({
      applicationDataForMagicTags:
        !loading && viewer ? viewer.jobApplications.edges[0].node : {},
      isApplicationDataForMagicTagsLoading: loading,
    }),
  }),
)(TemplateSelectContainer);
