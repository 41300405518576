import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import cx from 'classnames';
import Alert from 'components/Alert';
import { HIRED_STAGE } from 'consts/stageList';
import { stageName } from 'utils/translation';

import { showError } from 'utils/message';
import styles from './StageListFormModal.less';

const INITIAL_STAGES = [
  { name: 'Application' },
  { name: 'Interview' },
  { name: HIRED_STAGE },
];

function StageListFormModal({
  onSave,
  isVisible,
  onCancel,
  fieldErrors,
  clearFieldError,
  generalError,
  isSaving,
}) {
  const { t } = useTranslation();

  const [stageListName, setStageListName] = useState();
  const [stageListStages, setStageListStages] = useState(INITIAL_STAGES);

  const onListNameInputChange = useCallback(
    (e) => {
      setStageListName(e.target.value);
      clearFieldError('name');
    },
    [setStageListName, clearFieldError],
  );

  const onStageNameInputChange = useCallback(
    (value, stageIndex) => {
      if (value === HIRED_STAGE) return;

      const updatedStages = [...stageListStages];
      updatedStages[stageIndex].name = value;
      setStageListStages(updatedStages);
      clearFieldError('stages');
    },
    [stageListStages, clearFieldError],
  );

  const addStage = useCallback(() => {
    const newStage = { name: '' };
    setStageListStages((prev) => {
      const stagesExcludingHired = prev.slice(0, -1);

      return [...stagesExcludingHired, newStage, prev[prev.length - 1]];
    });
  }, [setStageListStages]);

  // hide delete icon to disable deleting the last stage
  const hideDeleteIcon = stageListStages.length <= 1;

  const deleteStage = useCallback(
    (index) => {
      if (stageListStages[index].name === HIRED_STAGE) return;
      const newStages = [...stageListStages];
      newStages.splice(index, 1);
      setStageListStages(newStages);
    },
    [stageListStages],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const stageNames = stageListStages.map((stage) => stage.name);
      const hasDuplicates = new Set(stageNames).size !== stageNames.length;

      if (hasDuplicates) {
        return showError(t('StageListFormModal_duplicateStages'));
      }
      onSave(stageListName, stageListStages);
    },
    [onSave, stageListName, stageListStages, t],
  );

  const onDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list

    const isHiredStage =
      stageListStages[result.source.index].name === HIRED_STAGE;
    const isMovingToEnd =
      result.destination.index === stageListStages.length - 1;

    if (isHiredStage && !isMovingToEnd) return;

    const reorderedStages = Array.from(stageListStages);
    const [removed] = reorderedStages.splice(result.source.index, 1);
    reorderedStages.splice(result.destination.index, 0, removed);

    // Make sure that Hired stage is always at the end of the list
    const hiredStage = reorderedStages.find(
      (stage) => stage.name === HIRED_STAGE,
    );
    reorderedStages.splice(reorderedStages.indexOf(hiredStage), 1);
    reorderedStages.push(hiredStage);

    setStageListStages(reorderedStages);
  };

  return (
    <Modal
      open={isVisible}
      maskClosable={false}
      title={t('StageListFormModal_title')}
      onCancel={onCancel}
      onOk={onSubmit}
      okDisabled={isSaving}
      okText={t('StageListFormModal_okText')}
    >
      {generalError && <Alert type="error" showIcon message={generalError} />}
      <Alert type="info" showIcon className={styles.infoAlert}>
        {t('StageListFormModal_info')}
      </Alert>
      <Form onFinish={onSubmit}>
        <Field error={fieldErrors.name}>
          <Input
            type="text"
            label={t('StageListFormModal_stageListName')}
            value={stageListName}
            onChange={onListNameInputChange}
            placeholder={t('StageListFormModal_stageListName')}
            autoFocus
          />
        </Field>
        <div>
          {fieldErrors.stages && (
            <Alert
              type="error"
              className={styles.infoAlert}
              showIcon
              message={fieldErrors.stages}
            />
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="stages">
              {(providedDrop) => (
                <div
                  ref={providedDrop.innerRef}
                  {...providedDrop.droppableProps}
                >
                  {stageListStages.map((stage, index) => (
                    <Draggable
                      key={index} // eslint-disable-line react/no-array-index-key
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(providedDrag, snapshot) => (
                        <div
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                          className={cx(
                            styles.stage,
                            snapshot.isDragging && styles.dragging,
                          )}
                          data-testid={`stage-${index + 1}`}
                        >
                          <div className={styles.draggingIcon}>
                            <DragOutlined aria-hidden />
                          </div>
                          <Field className={styles.inputField}>
                            <Input
                              type="text"
                              value={stageName(t, stage.name)}
                              onChange={(e) =>
                                onStageNameInputChange(e.target.value, index)
                              }
                              className={styles.input}
                              data-testid="stage-input"
                              disabled={stage.name === HIRED_STAGE}
                              autoFocus
                            />
                          </Field>

                          {!hideDeleteIcon && stage.name !== HIRED_STAGE && (
                            <Button
                              size="medium"
                              iconOnly
                              icon={<DeleteOutlined aria-hidden />}
                              aria-label="delete stage"
                              data-testid="delete-stage"
                              className={styles.deleteButton}
                              onClick={() => deleteStage(index)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {providedDrop.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className={styles.addButtonWrapper}>
            <Button
              size="small"
              icon={<PlusOutlined aria-hidden className={styles.icon} />}
              aria-label="add stage"
              data-testid="add-stage"
              className={styles.addButton}
              onClick={addStage}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
}

StageListFormModal.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func,
  isVisible: PropTypes.bool,
  generalError: PropTypes.string,
  isSaving: PropTypes.bool,
};

export default StageListFormModal;
