import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation, Trans } from 'i18n';

import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import createFilterInputObject from 'utils/createFilterInputObject.js';
import { logAndShowGenericError } from 'utils/log';
import { showError } from 'utils/message';

import { useLocation } from 'react-router';
import jobApplicationsQuery from './jobApplicationsQuery.gql';
import sendBulkEmailMutation from './sendBulkEmailMutation.gql';
import useCanSendBulkEmailing from './useCanSendBulkEmailing';
import CreateBulkEmail from './CreateBulkEmail';
import PreviewBulkEmail from './PreviewBulkEmail';
import ConfirmBulkEmail from './ConfirmBulkEmail';

const INITIAL_CREATE_STEP = 0;
const PREVIEW_AND_SEND_STEP = 1;
const SUCCESS_STEP = 2;

const getModalProps = ({
  modalStep,
  t,
  onNextStep,
  onPrevStep,
  onHide,
  isTemplateSelected,
  isSaving,
  emailsToSendCount,
}) => {
  if (modalStep === INITIAL_CREATE_STEP) {
    return {
      title: t('SendBulkEmailModal-title--create'),
      okText: t('SendBulkEmailModal_okText--next'),
      onOk: onNextStep,
      okDisabled: !isTemplateSelected,
    };
  }

  if (modalStep === PREVIEW_AND_SEND_STEP) {
    return {
      title: t('SendBulkEmailModal-title--preview'),
      // TODO: include number of addressees in okButton
      footer: (
        <>
          <Button
            key="back"
            onClick={onPrevStep}
            size="default"
            disabled={isSaving}
          >
            {t('SendBulkEmailModal_button--back')}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onNextStep}
            disabled={isSaving}
            size="default"
          >
            {t('SendBulkEmailModal_okText--sendToCount', {
              count: emailsToSendCount,
            })}
          </Button>
        </>
      ),
    };
  }

  if (modalStep === SUCCESS_STEP) {
    return {
      title: t('SendBulkEmailModal-title--confirmation'),
      footer: (
        <Button type="primary" onClick={onHide} size="default">
          {t('SendBulkEmailModal_okText--confirmation')}
        </Button>
      ),
    };
  }
};

function SendBulkEmailModal({ visible, onHide }) {
  const { t } = useTranslation();
  const location = useLocation();

  const [organizationPk] = useCurrentOrganization();
  const [sendBulkEmail] = useMutation(sendBulkEmailMutation);

  const candidateSearchFilterInput = createFilterInputObject({ location });

  const { data: applicationsData, loading: isApplicationsDataLoading } =
    useQuery(jobApplicationsQuery, {
      variables: {
        organizationPk,
        candidateSearchFilterInput,
      },
      fetchPolicy: 'network-only',
    });

  const {
    withEmailCount: emailsToSendCount,
    withoutEmailCount: applicationsWithoutEmailCount,
    totalCount,
  } = (!isApplicationsDataLoading &&
    applicationsData?.viewer.jobApplications) ||
  {};

  const { canSendBulkEmailing, disableSendingMessage, loading } =
    useCanSendBulkEmailing({ emailsToSendCount });

  const [modalStep, setModalStep] = useState(INITIAL_CREATE_STEP);
  const [template, setTemplate] = useState();

  const { mutate, isSaving } = useErrorFieldsMutation(sendBulkEmail);

  const onSumbit = useCallback(() => {
    mutate({
      variables: {
        organizationPk,
        emailTemplatePk: template.pk,
        candidateSearchFilterInput,
      },
    })
      .then(({ data }) => {
        if (data.sendBulkEmail.errors) {
          return data.sendBulkEmail.errors.message
            ? showError(data.addEmailTemplate.errors.message)
            : logAndShowGenericError('sendBulkEmail rejected', {
                organizationPk,
                emailTemplatePk: template.pk,
                candidateSearchFilterInput,
              });
        }
        setModalStep(modalStep + 1);
      })
      .catch(
        logAndShowGenericError('sendBulkEmail rejected', {
          organizationPk,
          emailTemplatePk: template.pk,
          candidateSearchFilterInput,
        }),
      );
  }, [mutate, organizationPk, template, candidateSearchFilterInput, modalStep]);

  const onNextStep = () => {
    if (modalStep === PREVIEW_AND_SEND_STEP) {
      return onSumbit();
    }
    setModalStep(modalStep + 1);
  };
  const onPrevStep = () => setModalStep(modalStep - 1);

  return (
    <Modal
      open={visible}
      onCancel={onHide}
      destroyOnClose
      maskClosable={false}
      {...getModalProps({
        modalStep,
        t,
        onNextStep,
        onPrevStep,
        onHide,
        isTemplateSelected: template,
        isSaving,
        emailsToSendCount,
      })}
    >
      {modalStep === INITIAL_CREATE_STEP &&
        !canSendBulkEmailing &&
        disableSendingMessage}
      {modalStep === INITIAL_CREATE_STEP && canSendBulkEmailing && (
        <CreateBulkEmail
          template={template}
          setTemplate={setTemplate}
          isLoading={isApplicationsDataLoading || loading}
          countMessage={
            <>
              <Trans
                i18nKey="SendBulkEmailModal-emailsCountInfo-totalCount"
                values={{ totalCount }}
              >
                <strong>emailsCountInfo-total:{{ totalCount }}</strong>
              </Trans>
              {applicationsWithoutEmailCount > 0 && (
                <Trans
                  i18nKey="SendBulkEmailModal-emailsCountInfo-applicationsCountInfo"
                  values={{ applicationsWithoutEmailCount, emailsToSendCount }}
                >
                  <>
                    <div>
                      emailsCountInfo-withoutEmailCount:
                      {{ applicationsWithoutEmailCount }}
                    </div>
                    <div>
                      emailsCountInfo-withEmailCount:{{ emailsToSendCount }}
                    </div>
                  </>
                </Trans>
              )}
            </>
          }
        />
      )}
      {modalStep === PREVIEW_AND_SEND_STEP && (
        <PreviewBulkEmail
          emailTemplatePk={template.pk}
          candidateSearchFilterInput={candidateSearchFilterInput}
          isSaving={isSaving}
        />
      )}
      {modalStep === SUCCESS_STEP && <ConfirmBulkEmail />}
    </Modal>
  );
}

SendBulkEmailModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

export default SendBulkEmailModal;
