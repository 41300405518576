import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { Popover } from 'antd';
import Button from 'components/Form/Button';
import styles from './index.less';

class DeleteItemPopover extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    deleteFunction: PropTypes.func.isRequired,
    message: PropTypes.string,
    deleteButtonText: PropTypes.string,
  };

  state = {
    visible: false,
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  content = (
    <div className={styles.container}>
      <span>
        {this.props.message
          ? this.props.t(this.props.message)
          : this.props.t('youSureWantToDeleteThisItem?')}
      </span>
      <div className={styles.buttons}>
        <Button
          onClick={this.hide}
          size="default"
          className={styles.btn}
          autoFocus
        >
          {this.props.t('cancel')}
        </Button>
        <Button
          onClick={this.props.deleteFunction}
          size="default"
          className={styles.btn}
          data-testid="confirm-delete"
          type="danger"
        >
          {this.props.deleteButtonText
            ? this.props.t(this.props.deleteButtonText)
            : this.props.t('delete')}
        </Button>
      </div>
    </div>
  );

  render() {
    return (
      <Popover
        content={this.content}
        trigger="click"
        open={this.state.visible}
        onOpenChange={this.handleVisibleChange}
        overlayClassName={styles.overlay}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export default withTranslation('translation')(DeleteItemPopover);
