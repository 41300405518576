import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import { PERMISSION_APPLICATION_EMAIL_SEND } from 'consts/permissions';

import useToggleState from 'hooks/useToggleState';
import usePermissions from 'hooks/usePermissions';

import Button from 'components/Form/Button';
import Tooltip from 'components/Tooltip';

import EmailModal from './EmailModal';
import useCanSendEmail from './useCanSendEmail';

function EmailComposeButton({
  application,
  contactDetails,
  children,
  icon,
  size,
  replyToEmailInstance,
  fullWidth,
}) {
  const [isEmailModalOpen, onEmailModalOpen, onEmailModalClose] =
    useToggleState(false);

  const { hasEmailingModule, canSendEmail, disabledSendingMessage, loading } =
    useCanSendEmail({ contactDetails });

  const { t } = useTranslation();

  const [hasApplicationEmailPermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_APPLICATION_EMAIL_SEND]);

  const isJobPositionActive = application.jobPosition.isActive;

  const jobPositionInactiveMessage = useMemo(
    () =>
      !isJobPositionActive &&
      t('ApplicationMessage_tooltip--inactiveJobPosition'),
    [isJobPositionActive, t],
  );

  return (
    hasEmailingModule && (
      <>
        <Tooltip title={jobPositionInactiveMessage || disabledSendingMessage}>
          <span>
            <Button
              onClick={
                hasApplicationEmailPermission
                  ? onEmailModalOpen
                  : showNoPermissionMessage
              }
              disabled={!isJobPositionActive || !canSendEmail || loading}
              data-testid="compose-email"
              data-role="button-compose-email"
              icon={icon}
              size={size}
              fullWidth={fullWidth}
            >
              {children /* name of button must be send here */}
            </Button>
          </span>
        </Tooltip>
        <EmailModal
          application={application}
          contactDetails={contactDetails}
          isOpen={isEmailModalOpen}
          onClose={onEmailModalClose}
          replyToEmailInstance={replyToEmailInstance}
        />
      </>
    )
  );
}

EmailComposeButton.propTypes = {
  application: PropTypes.object,
  contactDetails: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),

  // contains icon component
  icon: PropTypes.object,

  // contains size of button
  size: PropTypes.string,

  // contains an object with information about email meessage
  replyToEmailInstance: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default EmailComposeButton;
