import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon, { EllipsisOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Dropdown from 'components/Dropdown';
import Menu from 'components/Menu';
import useFocusEffect from 'hooks/useFocusEffect';
import styles from './index.less';

const renderOptions = (items, onItemClick, menuRef, setDropdownOpen) => {
  const menuItems = items.map(
    ({ key, content, disabled, iconComponent, withClickEvent = true }) => ({
      key,
      label: content,
      disabled,
      onClick:
        withClickEvent &&
        (() => {
          onItemClick(key);
          // closes dropdown on item click
          setDropdownOpen(false);
        }),
      icon: (
        <Icon
          component={iconComponent}
          className={styles.icon}
          data-testid="antd-icon"
        />
      ),
    }),
  );

  return <Menu items={menuItems} ref={menuRef} />;
};

function MoreMenu({
  items,
  onItemClick,
  menuDisabled,
  buttonType,
  trigger,
  placement,
  menuTitle,
}) {
  // state to manage dropdown visiility
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  // move focus to dropdown menu on dropdown open
  useFocusEffect(menuRef, isDropdownOpen);
  return (
    <Dropdown
      trigger={[trigger || 'click']}
      open={isDropdownOpen}
      overlay={renderOptions(items, onItemClick, menuRef, setDropdownOpen)}
      className={styles.candidateOptions}
      disabled={menuDisabled}
      placement={placement}
      onOpenChange={setDropdownOpen}
    >
      <Button
        className={styles.dropdownButton}
        icon={<EllipsisOutlined aria-hidden />}
        size="small"
        buttonType={buttonType}
        data-testid="more-menu-button"
        aria-label={menuTitle ? `${menuTitle} more menu` : 'more menu button'}
      />
    </Dropdown>
  );
}

MoreMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      key: PropTypes.string.isRequired,
      iconComponent: PropTypes.elementType,
      content: PropTypes.node.isRequired,
      withClickEvent: PropTypes.bool,
    }),
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  menuDisabled: PropTypes.bool,
  buttonType: PropTypes.string,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  menuTitle: PropTypes.string,
};

export default MoreMenu;
