import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Trans } from 'i18n';
import { Card } from 'antd';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import { nl2br } from 'utils/text';
import Alert from 'components/Alert/index';
import Loader from 'components/Loader';
import emailTemplatesListQuery from 'pages/settings/email-templates/emailTemplatesListQuery.gql';
import TemplateSelect from 'pages/candidates/ApplicationDetails/TemplateSelect/TemplateSelect';

import styles from './CreateBulkEmail.less';

function CreateBulkEmail({ setTemplate, template, countMessage, isLoading }) {
  const [organizationPk] = useCurrentOrganization();
  const { data, loading } = useQuery(emailTemplatesListQuery, {
    variables: { organizationPk },
    fetchPolicy: 'network-only',
  });

  const emailTemplatesList =
    !loading && data ? data.viewer.emailTemplates.edges : [];

  const handleTemplateSelect = (title, content, pk) => {
    setTemplate({
      title,
      content,
      pk,
    });
  };

  if (isLoading) {
    return <Loader fullSize />;
  }

  return (
    <>
      <Alert
        type="info"
        message={
          <>
            <p data-testid="bulk-email-count-message">{countMessage}</p>
            <Trans i18nKey="CreateBulkEmail_info">
              <p>Multiple emails info</p>
              <p>Multiple applications info</p>
            </Trans>
          </>
        }
        className={styles.infoAlert}
      />
      <TemplateSelect
        emailTemplatesList={emailTemplatesList}
        onSelectEmailTemplate={handleTemplateSelect}
        isDataLoading={loading}
        persistSelection
        fullWidth
      />
      {template && (
        <Card size="small" className={styles.contentBox}>
          {nl2br(template.content)}
        </Card>
      )}
    </>
  );
}

CreateBulkEmail.propTypes = {
  setTemplate: PropTypes.func,
  template: PropTypes.object,
  countMessage: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default CreateBulkEmail;
