import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  EnvironmentOutlined,
  LockOutlined,
  HourglassOutlined,
  LinkOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import { useTranslation } from 'i18n';
import cx from 'classnames';
import copyToClipboard from 'copy-to-clipboard';
import { useQuery } from '@apollo/client';

import Rating from 'pages/candidates/Rating';
import Stage from 'pages/candidates/Stage';
import ApplicationActivityFeed from 'pages/candidates/ApplicationActivityFeed';
import DateWithIcon from 'components/DateWithIcon';
import MoreMenu from 'components/MoreMenu';
import RemoteTag from 'components/RemoteTag';
import Alert from 'components/Alert';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';
import NoPermissions from 'components/NoPermissions';
import Link from 'components/Link';
import { EmailPreferencesProvider } from 'providers/EmailPreferencesProvider';

import { logAndShowGenericError } from 'utils/log';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { PERMISSION_APPLICATION_MANAGE } from 'consts/permissions';

import RejectApplicationModal from 'components/RejectApplicationModal';
import emailTemplatesListQuery from 'pages/settings/email-templates/emailTemplatesListQuery.gql';
import CustomFieldsList from './CustomFieldsValues';
import AssignedTo from './AssignedTo';
import ApplicationToolbar from './ApplicationToolbar';

import styles from './index.less';

const getMoreMenu = (t, isDisabled) => [
  {
    key: 'copyLink',
    iconComponent: LinkOutlined,
    content: t('copyLink'),
    dataRole: 'copy-application-link',
  },
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('deleteApplication'),
    disabled: isDisabled,
    dataRole: 'delete-application',
  },
];

const onPositionClick = (withLinkableHeaderNames) => (e) => {
  if (!withLinkableHeaderNames) {
    e.preventDefault();
  }
};

function ApplicationDetails({
  applications,
  activeApplicationPk,
  organizationPk,
  candidate,
  candidatePk,
  deleteApplication,
  withLinkableHeaderNames,
  popupContainerRef,
  contactDetails,
  onApplicationDeleted,
  permissions,
}) {
  const { t } = useTranslation();
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);

  // Fetch email templates for the provider
  const { data: templatesData, loading: templatesLoading } = useQuery(
    emailTemplatesListQuery,
    {
      variables: { organizationPk },
    },
  );

  const onDeleteApplication = useCallback(() => {
    deleteApplication(activeApplicationPk, organizationPk)
      .then(
        ({
          data: {
            deleteApplication: { errors },
          },
        }) => {
          if (!errors) {
            onApplicationDeleted();
          } else {
            message.error(t('deleteApplicationError'));
          }
        },
      )
      .catch(
        logAndShowGenericError('deleteApplication rejected', {
          activeApplicationPk,
          organizationPk,
        }),
      );
  }, [
    deleteApplication,
    activeApplicationPk,
    organizationPk,
    onApplicationDeleted,
    t,
  ]);

  const toggleRejectModal = useCallback(() => {
    setIsRejectModalVisible((prev) => !prev);
  }, []);

  const currentApplication = applications.find(
    ({ node }) => node.pk === activeApplicationPk,
  );
  if (!currentApplication) return null;

  const {
    jobPosition,
    assignedTo,
    ratingOption,
    stage,
    pk,
    id,
    isRejected,
    rejectionReason,
  } = currentApplication.node;

  const isJobPositionActive = jobPosition.isActive;
  const currentUrl = window.location.origin;
  const hasApplicationManagePermission = permissions.includes(
    PERMISSION_APPLICATION_MANAGE,
  );

  return (
    <EmailPreferencesProvider
      organizationPk={organizationPk}
      emailTemplatesList={templatesData?.viewer?.emailTemplates?.edges || []}
      isLoading={templatesLoading}
    >
      <div
        className={styles.applicationDetails}
        data-testid="ApplicationDetails"
      >
        {isRejected && (
          <Alert
            type="error"
            message={t('applicationRejected')}
            description={rejectionReason?.name}
            className={styles.rejectionAlert}
          />
        )}
        <div className={styles.applicationHeader}>
          <div className={styles.applicationPosition}>
            <Link
              to={`/candidates/${candidatePk}/${pk}`}
              className={cx(!withLinkableHeaderNames && styles.notLink)}
              data-testid="application-position"
              onClick={onPositionClick(withLinkableHeaderNames)}
            >
              {jobPosition.position}
              {!isJobPositionActive && (
                <>
                  {' '}
                  <LockOutlined />
                </>
              )}
            </Link>
            <MoreMenu
              items={getMoreMenu(t, !hasApplicationManagePermission)}
              onItemClick={(key) => {
                if (key === 'delete') {
                  confirmDeleteModal(
                    t('sureWantToDeleteApplication'),
                    onDeleteApplication,
                  );
                } else if (key === 'copyLink') {
                  copyToClipboard(
                    `${currentUrl}/candidates/${candidatePk}/${activeApplicationPk}`,
                  );
                  message.info(t('applicationLinkCopiedToClipboard'));
                }
              }}
            />
          </div>
          <div className={styles.applicationHeaderInfo}>
            {(jobPosition.location || jobPosition.isRemote) && (
              <div className={styles.applicationLocation}>
                <EnvironmentOutlined /> {jobPosition.location}{' '}
                {jobPosition.isRemote && <RemoteTag />}
              </div>
            )}
            {jobPosition.deadlineAt && (
              <DateWithIcon
                date={jobPosition.deadlineAt}
                iconComponent={HourglassOutlined}
                text={t('ends')}
              />
            )}
          </div>
        </div>
        <div className={styles.customFieldsContainer}>
          <CustomFieldsList
            applicationPk={currentApplication.node.pk}
            candidatePk={candidatePk}
          />
        </div>
        {!isJobPositionActive && (
          <div className={styles.alertWrapper}>
            <Alert
              type="warning"
              cta={
                <ActivateOrChangePlanButton
                  jobPositionPk={jobPosition.pk}
                  initialStage="activate"
                >
                  {t('activate')}
                </ActivateOrChangePlanButton>
              }
            >
              {t('thisJobPositionIsNotActive')}
            </Alert>
          </div>
        )}
        {!hasApplicationManagePermission ? (
          <div className={styles.alertWrapper}>
            <NoPermissions />
          </div>
        ) : null}
        <div
          className={cx(styles.applicationCandidateInfo, {
            [styles.rejectedContent]: isRejected,
          })}
        >
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('rating')}
            </div>
            <Rating
              data-testid="rating-select"
              rating={ratingOption}
              ratingScalePk={jobPosition.ratingScale.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              disabled={
                !isJobPositionActive ||
                !hasApplicationManagePermission ||
                isRejected
              }
              aria-label={t('rating')}
              popupContainer={popupContainerRef}
            />
          </div>
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('stage')}
            </div>
            <Stage
              data-testid="stage-select"
              stagePk={stage.pk}
              stageListPk={stage.stageList.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              popupContainerRef={popupContainerRef}
              disabled={
                !isJobPositionActive ||
                !hasApplicationManagePermission ||
                isRejected
              }
              isRejected={isRejected}
              rejectionReason={rejectionReason?.name}
              aria-label={t('stage')}
              application={currentApplication.node}
              contactDetails={contactDetails}
            />
          </div>
          <div className={styles.selectContainer}>
            <div className={styles.applicationSubsectionTitle}>
              {t('assignedTo')}
            </div>
            <AssignedTo
              data-testid="assigned-to-select"
              value={assignedTo && assignedTo.pk}
              applicationPk={activeApplicationPk}
              applicationId={id}
              candidatePk={candidatePk}
              popupContainerRef={popupContainerRef}
              disabled={
                !isJobPositionActive ||
                !hasApplicationManagePermission ||
                isRejected
              }
              aria-label={t('assignedTo')}
            />
          </div>
        </div>
        <ApplicationToolbar
          application={currentApplication.node}
          candidate={candidate}
          contactDetails={contactDetails}
          onReject={toggleRejectModal}
          isRejected={isRejected}
          organizationPk={organizationPk}
          hasApplicationManagePermission={hasApplicationManagePermission}
        />
        <div className={cx({ [styles.rejectedContent]: isRejected })}>
          <ApplicationActivityFeed
            applicationPk={activeApplicationPk}
            reversed
          />
        </div>
        <RejectApplicationModal
          visible={isRejectModalVisible}
          onCancel={toggleRejectModal}
          applicationId={activeApplicationPk}
          organizationPk={organizationPk}
          candidateId={candidatePk}
          application={currentApplication.node}
          contactDetails={contactDetails}
        />
      </div>
    </EmailPreferencesProvider>
  );
}

ApplicationDetails.propTypes = {
  applications: PropTypes.array,
  activeApplicationPk: PropTypes.string,
  organizationPk: PropTypes.string.isRequired,
  candidate: PropTypes.object.isRequired,
  candidatePk: PropTypes.string.isRequired,
  deleteApplication: PropTypes.func.isRequired,
  withLinkableHeaderNames: PropTypes.bool,
  popupContainerRef: PropTypes.object,
  contactDetails: PropTypes.array.isRequired,
  onApplicationDeleted: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
};

export default ApplicationDetails;
