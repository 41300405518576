import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'antd';
import Select from 'components/Form/Select';
import cx from 'classnames';
import { withTranslation } from 'i18n';
import { useEmailPreferences } from 'providers/EmailPreferencesProvider';
import useCanSendEmail from 'components/EmailComposeButton/useCanSendEmail';
import EmailModal from 'components/EmailComposeButton/EmailModal';
import { stageName } from 'utils/translation';
import styles from './index.less';

const generateStageIcons = (stages, idx) =>
  stages.map((o, i) => (
    <div key={o.pk} className={cx(styles.dot, i <= idx && styles.filled)} />
  ));

function StageOptionElement({ name, stages, index, t }) {
  return (
    <div className={styles.label}>
      <div>{t(name)}</div>
      <div className={styles.stageDots}>
        {generateStageIcons(stages, index)}
      </div>
    </div>
  );
}

StageOptionElement.propTypes = {
  name: PropTypes.string.isRequired,
  stages: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

function DropdownContent({
  menu,
  hasEmailingModule,
  tooltipMessage,
  stageEmailEnabled,
  canSendEmail,
  handleCheckboxChange,
  t,
}) {
  return (
    <>
      {menu}
      {hasEmailingModule && (
        <div className={styles.dropdownFooter}>
          <Tooltip title={tooltipMessage}>
            <Checkbox
              checked={stageEmailEnabled && canSendEmail}
              onChange={handleCheckboxChange}
              disabled={!canSendEmail}
            >
              {t('StageSelect_checkbox-suggestEmails')}
            </Checkbox>
          </Tooltip>
        </div>
      )}
    </>
  );
}

DropdownContent.propTypes = {
  menu: PropTypes.node,
  hasEmailingModule: PropTypes.bool.isRequired,
  tooltipMessage: PropTypes.string,
  stageEmailEnabled: PropTypes.bool.isRequired,
  canSendEmail: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

function StageSelect({
  stages,
  className,
  selected,
  displayCards,
  nonChangeable,
  onChange,
  t,
  popupContainerRef,
  disabled,
  application,
  contactDetails,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedStagePk, setSelectedStagePk] = useState(selected);

  // Allow optional usage of emailPreferences
  const emailPreferences = useEmailPreferences(true);
  const { hasEmailingModule, canSendEmail } = useCanSendEmail({
    contactDetails: contactDetails || [],
  });

  // Derive stable references
  const stageEmailEnabled = emailPreferences?.stageEmailEnabled ?? false;

  const getStageTemplate = useCallback(
    (stagePk) => emailPreferences?.getStageTemplate(stagePk) ?? null,
    [emailPreferences],
  );

  const setStageTemplateFn = useCallback(
    (stagePk, templatePk) => {
      if (emailPreferences?.setStageTemplate) {
        emailPreferences.setStageTemplate(stagePk, templatePk);
      }
    },
    [emailPreferences],
  );

  const setStageEmailEnabledFn = useCallback(
    (enabled) => {
      if (emailPreferences?.setStageEmailEnabled) {
        emailPreferences.setStageEmailEnabled(enabled);
      }
    },
    [emailPreferences],
  );

  const handleCheckboxChange = useCallback(
    (e) => {
      if (hasEmailingModule && canSendEmail) {
        setStageEmailEnabledFn(e.target.checked);
      }
    },
    [hasEmailingModule, canSendEmail, setStageEmailEnabledFn],
  );

  const tooltipMessage = useMemo(() => {
    if (!hasEmailingModule) {
      return t('StageSelect_tooltip-noEmailModule');
    }
    if (!canSendEmail) {
      return t('StageSelect_tooltip-noEmailContacts');
    }
    return null;
  }, [hasEmailingModule, canSendEmail, t]);

  const handleChange = useCallback(
    async (val) => {
      setIsLoading(true);
      await onChange(val);
      setIsLoading(false);
      setSelectedStagePk(val);

      if (stageEmailEnabled && hasEmailingModule && canSendEmail) {
        setShowEmailModal(true);
      }
    },
    [onChange, stageEmailEnabled, hasEmailingModule, canSendEmail],
  );

  const handleEmailModalClose = useCallback(() => {
    setShowEmailModal(false);
  }, []);

  const handleTemplateSelect = useCallback(
    (templatePk) => {
      if (selectedStagePk && templatePk) {
        setStageTemplateFn(selectedStagePk, templatePk);
      }
    },
    [selectedStagePk, setStageTemplateFn],
  );

  const selectedStage = stages.find((o) => o.pk === selected);
  const selectedIndex = stages.findIndex((o) => o.pk === selected);

  const dropdownRender = useCallback(
    (menu) => (
      <DropdownContent
        menu={menu}
        hasEmailingModule={hasEmailingModule}
        tooltipMessage={tooltipMessage}
        stageEmailEnabled={stageEmailEnabled}
        canSendEmail={canSendEmail}
        handleCheckboxChange={handleCheckboxChange}
        t={t}
      />
    ),
    [
      hasEmailingModule,
      tooltipMessage,
      stageEmailEnabled,
      canSendEmail,
      handleCheckboxChange,
      t,
    ],
  );

  const choices = useMemo(
    () =>
      stages.map(({ pk, name }, i) => ({
        value: pk,
        optionElement: (
          <StageOptionElement
            name={stageName(t, name, true)}
            stages={stages}
            index={i}
            t={t}
          />
        ),
      })),
    [stages, t],
  );

  return nonChangeable ? (
    <div className={cx(className, styles.label)}>
      {selectedStage && (
        <>
          <div>{stageName(t, selectedStage.name)}</div>
          <div className={styles.stageDots}>
            {generateStageIcons(stages, selectedIndex)}
          </div>
        </>
      )}
    </div>
  ) : (
    <>
      <Select
        data-testid={rest['data-testid'] || ''}
        selected={selected}
        className={cx(
          styles.stageSelect,
          className,
          displayCards && styles.cardStageSelect,
        )}
        dropdownClassName={styles.dropdown}
        value={stages.length > 0 && selected}
        choices={choices}
        withBorders={false}
        placeholder={t('placeholderStage')}
        popupContainer={popupContainerRef}
        onChange={handleChange}
        disabled={disabled || isLoading}
        dropdownRender={dropdownRender}
        {...rest}
      />

      {showEmailModal && (
        <EmailModal
          data-testid="stage-email-modal"
          application={application}
          contactDetails={contactDetails}
          isOpen={showEmailModal}
          onClose={handleEmailModalClose}
          stagePk={selectedStagePk}
          onTemplateSelect={handleTemplateSelect}
          defaultTemplate={
            selectedStagePk ? getStageTemplate(selectedStagePk) : null
          }
        />
      )}
    </>
  );
}

StageSelect.propTypes = {
  className: PropTypes.string,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.string,
  displayCards: PropTypes.bool,
  nonChangeable: PropTypes.bool,
  t: PropTypes.func,
  popupContainerRef: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  application: PropTypes.object,
  contactDetails: PropTypes.array,
};

export default withTranslation('translation')(StageSelect);
