import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button as AntButton } from 'antd';
import { useNavigate } from 'react-router';
import styles from './Button.less';

/**
 * Wrapper around AntD Button — used for abstraction
 */
const Button = forwardRef(
  (
    {
      size = 'large',
      to,
      onClick,
      className,
      hiddenSubmit,
      iconOnly,
      htmlType,
      fullWidth,
      ...rest
    },
    ref,
  ) => {
    let onClickProps = { onClick };
    const navigate = useNavigate();
    // if 'to' prop is passed we want it to behave as <Link />
    if (to) {
      onClickProps = {
        onClick: (e) => {
          navigate(to); // update history
          if (onClick) {
            onClick(e); // but also fire event if onClick was passed too
          }
        },
      };
    }

    return (
      <AntButton
        size={size}
        className={cx(
          styles.button,
          className,
          hiddenSubmit && styles.hidden,
          iconOnly && styles.iconOnly,
          fullWidth && styles.fullWidth,
        )}
        htmlType={hiddenSubmit ? 'submit' : htmlType}
        {...onClickProps}
        {...rest}
        ref={ref}
      />
    );
  },
);

Button.propTypes = {
  size: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  hiddenSubmit: PropTypes.bool,
  iconOnly: PropTypes.bool,
  htmlType: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Button;
