import React from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import JobPositionPermissionsTable from 'pages/job-positions/JobPositionAddPermissions/JobPositionPermissionsTable';
import Alert from 'components/Alert';

import { Trans } from 'react-i18next';
import Link from 'components/Link';
import styles from './JobPositionAuthorizedUsersDialog.less';

export default function JobPositionAuthorizedUsersDialog({
  visible,
  onClose,
  jobPositionPk,
}) {
  return (
    <Modal open={visible} onCancel={onClose} onOk={onClose} width={700}>
      <Alert
        type="info"
        showIcon
        className={styles.infoAlert}
        description={
          <Trans i18nKey="RestrictedUsersPermissions_info">
            <p>
              If you want to restrict access to this process for any user, first
              change his or her permission role in the
              <Link to="/teamMembers"> "Team members" tab</Link> to "Limited
              access".
            </p>
            <p>
              Attention! Only the "Owner" of the account can change users
              permission role.
            </p>
            <p>
              Users with permission roles: "Full access", "Administrator" and
              "Owner" have access to all recruitment processes.
            </p>
          </Trans>
        }
      />
      <JobPositionPermissionsTable jobPositionPk={jobPositionPk} />
    </Modal>
  );
}

JobPositionAuthorizedUsersDialog.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  jobPositionPk: PropTypes.string,
};
