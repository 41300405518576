/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  getEmailEnabled,
  setEmailEnabled as setEmailEnabledStorage,
  getRejectionTemplate as getStoredRejectionTemplate,
  setRejectionTemplate as setStoredRejectionTemplate,
  getStageTemplate as getStoredStageTemplate,
  setStageTemplate as setStoredStageTemplate,
  getStageEmailEnabled,
  setStageEmailEnabled as setStageEnabledStorage,
} from 'utils/emailPreferences';

type Template = {
  pk: string;
  title: string;
  content: string;
};

type TemplateNode = {
  node: Template;
};

type EmailPreferencesContextValue = {
  // Email preferences
  emailEnabled: boolean;
  setEmailEnabled: (enabled: boolean) => void;

  // Template management
  templates: Template[];
  isLoading: boolean;

  // Rejection template handling
  getRejectionTemplate: (reasonPk: string) => Template | null;
  setRejectionTemplate: (reasonPk: string, templatePk: string) => void;

  // Stage template handling
  stageEmailEnabled: boolean;
  setStageEmailEnabled: (enabled: boolean) => void;
  getStageTemplate: (stagePk: string) => Template | null;
  setStageTemplate: (stagePk: string, templatePk: string) => void;
};

type EmailPreferencesProviderProps = {
  children: React.ReactNode;
  organizationPk: string;
  emailTemplatesList: TemplateNode[];
  isLoading?: boolean;
};

export const EmailPreferencesContext =
  createContext<EmailPreferencesContextValue | null>(null);

export function EmailPreferencesProvider({
  children,
  organizationPk,
  emailTemplatesList,
  isLoading = false,
}: EmailPreferencesProviderProps) {
  // Transform templates directly since emailTemplatesList changes infrequently
  const templates = (emailTemplatesList || []).map(({ node }) => node);

  // Create templates map directly
  const templatesMap = useMemo(
    () =>
      templates.reduce<Record<string, Template>>((acc, template) => {
        acc[template.pk] = template;
        return acc;
      }, {}),
    [templates],
  );

  // Initialize state from local storage
  const [emailEnabled, setEmailEnabledState] = useState(() =>
    getEmailEnabled(organizationPk),
  );
  const [stageEmailEnabled, setStageEmailEnabledState] = useState(() =>
    getStageEmailEnabled(organizationPk),
  );

  const setEmailEnabled = useCallback(
    (enabled: boolean) => {
      setEmailEnabledStorage(organizationPk, enabled);
      setEmailEnabledState(enabled);
    },
    [organizationPk],
  );

  const setStageEmailEnabled = useCallback(
    (enabled: boolean) => {
      setStageEnabledStorage(organizationPk, enabled);
      setStageEmailEnabledState(enabled);
    },
    [organizationPk],
  );

  const getRejectionTemplate = useCallback(
    (reasonPk: string): Template | null => {
      const templatePk = getStoredRejectionTemplate(organizationPk, reasonPk);
      return templatePk ? templatesMap[templatePk] || null : null;
    },
    [organizationPk, templatesMap],
  );

  const setRejectionTemplate = useCallback(
    (reasonPk: string, templatePk: string) => {
      setStoredRejectionTemplate(organizationPk, reasonPk, templatePk);
    },
    [organizationPk],
  );

  const getStageTemplate = useCallback(
    (stagePk: string): Template | null => {
      const templatePk = getStoredStageTemplate(organizationPk, stagePk);
      return templatePk ? templatesMap[templatePk] || null : null;
    },
    [organizationPk, templatesMap],
  );

  const setStageTemplate = useCallback(
    (stagePk: string, templatePk: string) => {
      setStoredStageTemplate(organizationPk, stagePk, templatePk);
    },
    [organizationPk],
  );

  // Memoize the context value
  const value = useMemo(
    () => ({
      // Email preferences
      emailEnabled,
      setEmailEnabled,

      // Template management
      templates,
      isLoading,

      // Rejection template handling
      getRejectionTemplate,
      setRejectionTemplate,

      // Stage template handling
      stageEmailEnabled,
      setStageEmailEnabled,
      getStageTemplate,
      setStageTemplate,
    }),
    [
      emailEnabled,
      setEmailEnabled,
      templates,
      isLoading,
      getRejectionTemplate,
      setRejectionTemplate,
      stageEmailEnabled,
      setStageEmailEnabled,
      getStageTemplate,
      setStageTemplate,
    ],
  );

  return (
    <EmailPreferencesContext.Provider value={value}>
      {children}
    </EmailPreferencesContext.Provider>
  );
}

/**
 * Hook to access email preferences context.
 * Must be used within EmailPreferencesProvider.
 *
 * @throws Error if used outside of EmailPreferencesProvider
 */
export function useEmailPreferences(
  optional = false,
): EmailPreferencesContextValue | null {
  const context = useContext(EmailPreferencesContext);
  if (!context && !optional) {
    throw new Error(
      'useEmailPreferences must be used within EmailPreferencesProvider',
    );
  }
  return context;
}
