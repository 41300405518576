import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Modal, Slider } from 'antd';
import { useTranslation, Trans } from 'i18n';
import cx from 'classnames';
import Link from 'components/Link';

import Alert from 'components/Alert';
import Loader from 'components/Loader';
import NoPermissions from 'components/NoPermissions';

import formatCurrency from 'utils/formatCurrency';
import { MAP_STAGES } from 'consts/activateOrChangePlanStages';
import { PLAN_TYPES } from 'consts/planTypes';
import { PERMISSION_BILLING_PLAN_MANAGE } from 'consts/permissions';

import usePermissions from 'hooks/usePermissions';

import ProsAndConsList from './ProsAndConsList';

import styles from './ChangePlanModal.less';

const MAX_SLIDER_VALUE = 20;

const getDefaultModalProps = (
  modalStage,
  billing,
  newSubscriptionCredits,
  onIsActiveToggle,
  onUpgrade,
  onPlanDecrease,
  onPlanIncrease,
  onConfirmChangePlan,
  onThankYou,
  onCancel,
  isDataSaving,
  t,
  hasBillingPlanManagePermission,
) => {
  const { availableCredits, monthlyCredits } = billing;

  const textOrLoading = (text) => (isDataSaving ? <LoadingOutlined /> : text);

  if (modalStage === MAP_STAGES.ACTIVATE) {
    return {
      title: t('Modal_title--activate'),
      okText: textOrLoading(t('Modal_okText--activate')),
      onOk: onIsActiveToggle(true),
      okButtonProps: {
        disabled: isDataSaving,
      },
    };
  }

  if (modalStage === MAP_STAGES.DEACTIVATE) {
    return {
      title: t('Modal_title--deactivate'),
      okText: textOrLoading(t('Modal_okText--deactivate')),
      onOk: onIsActiveToggle(false),
      okButtonProps: {
        type: 'danger',
        disabled: isDataSaving,
      },
    };
  }

  if (modalStage === MAP_STAGES.UPGRADE) {
    return {
      title: t('Modal_title--upgrade'),
      okText: t('Modal_okText--upgrade'),
      onOk: onUpgrade,
      okButtonProps: {
        disabled: !hasBillingPlanManagePermission,
      },
    };
  }

  if (modalStage === MAP_STAGES.CHANGE_PLAN) {
    return {
      title: t('Modal_title--changePlan'),
      okText:
        newSubscriptionCredits < monthlyCredits
          ? textOrLoading(t('Modal_okText--decreasePlan'))
          : textOrLoading(t('Modal_okText--increasePlan')),
      okButtonProps: {
        disabled:
          monthlyCredits === newSubscriptionCredits ||
          monthlyCredits - newSubscriptionCredits > availableCredits ||
          isDataSaving,
        type: newSubscriptionCredits < monthlyCredits ? 'danger' : 'primary',
      },
      onOk:
        newSubscriptionCredits < monthlyCredits
          ? onPlanDecrease
          : onPlanIncrease,
    };
  }

  if (modalStage === MAP_STAGES.CONFIRM_CHANGE_PLAN) {
    return {
      title: t('Modal_title--confirmChangePlan'),
      okText: textOrLoading(t('Modal_okText--confirmChangePlan')),
      onOk: onConfirmChangePlan,
      okButtonProps: {
        type: 'danger',
        disabled: isDataSaving,
      },
    };
  }

  if (modalStage === MAP_STAGES.THANK_YOU) {
    return {
      title: t('Modal_title--thankYou'),
      okText: t('Modal_okText--thankYou'),
      onOk: onThankYou,
      cancelButtonProps: {
        style: { display: 'none' },
      },
    };
  }

  if (modalStage === MAP_STAGES.ERROR) {
    return {
      title: t('Modal_title--error'),
      okText: t('Modal_okText--error'),
      onOk: onCancel,
      okButtonProps: {
        type: 'default',
      },
      cancelButtonProps: {
        style: { display: 'none' },
      },
    };
  }
};

const getActivateJobPositionPros = (t) => [
  t('Modal_activatePros--addNewCandidates'),
  t('Modal_activatePros--seeNewCandidates'),
  t('Modal_activatePros--editCandidates'),
];

const getDecreasePlanCons = (t, newSubscriptionCredits) => [
  `${t('Modal_decreasePlanCons--decreaseTo')} ${newSubscriptionCredits}`,
  t('Modal_decreasePlanCons--seeNewCandidates'),
];

function ChangePlanModal({
  isModalVisible,
  jobPositionPk,
  billing,
  isVerified,
  modalStage,
  newSubscriptionCredits,
  isInitialStageSet,
  isNewSubscriptionCreditsSet,
  isDataSaving,
  onCancel,
  onSliderChange,
  onIsActiveToggle,
  onUpgrade,
  onPlanDecrease,
  onPlanIncrease,
  onConfirmChangePlan,
  onThankYou,
}) {
  const { t } = useTranslation();
  const [hasBillingPlanManagePermission] = usePermissions([
    PERMISSION_BILLING_PLAN_MANAGE,
  ]);

  const {
    availableCredits,
    monthlyCredits,
    creditPrice,
    currency,
    planType,
    creditUsageList,
  } = billing;

  // Wait until all initial data is set to prevent displaying wrong modal
  const isInitialDataReady =
    isInitialStageSet &&
    isNewSubscriptionCreditsSet &&
    Object.keys(billing).length > 0;

  // Check if this jobPosition has already been active in current subscription
  let isJobPositionUsed;
  if (isInitialDataReady) {
    isJobPositionUsed = !!creditUsageList.find(
      (o) => o.jobPositionPk === jobPositionPk,
    );
  }

  const loadingProps = {
    okButtonProps: {
      disabled: !isInitialDataReady,
    },
    cancelButtonProps: {
      disabled: !isInitialDataReady,
    },
  };

  return (
    <Modal
      {...loadingProps}
      open={isModalVisible}
      closable={false}
      maskClosable={false}
      destroyOnClose
      {...getDefaultModalProps(
        modalStage,
        billing,
        newSubscriptionCredits,
        onIsActiveToggle,
        onUpgrade,
        onPlanDecrease,
        onPlanIncrease,
        onConfirmChangePlan,
        onThankYou,
        onCancel,
        isDataSaving,
        t,
        hasBillingPlanManagePermission,
      )}
      cancelText={t('cancel')}
      onCancel={onCancel}
      wrapClassName={cx(
        styles.modalContainer,
        [MAP_STAGES.ACTIVATE, MAP_STAGES.THANK_YOU].includes(modalStage) &&
          styles.bodyPrimary,
        [
          MAP_STAGES.DEACTIVATE,
          MAP_STAGES.CONFIRM_CHANGE_PLAN,
          MAP_STAGES.ERROR,
        ].includes(modalStage) && styles.bodyDanger,
      )}
    >
      {isInitialDataReady ? (
        <div>
          {modalStage === MAP_STAGES.ACTIVATE && (
            <ProsAndConsList
              title={
                <>
                  {planType === PLAN_TYPES.FREE &&
                    t('Modal_body--activateInFreePlan')}
                  {planType === PLAN_TYPES.MONTHLY && !isJobPositionUsed && (
                    <Trans
                      i18nKey="Modal_body--remainingJobPositions"
                      count={availableCredits}
                    >
                      You have <b>{{ availableCredits }}</b> credits left. Would
                      you like to activate this job position?
                    </Trans>
                  )}
                </>
              }
              listTitle={t('Modal_listTitle--activatingWillAllowYouTo')}
              list={getActivateJobPositionPros(t)}
              type="pros"
            />
          )}
          {modalStage === MAP_STAGES.DEACTIVATE && (
            <ProsAndConsList
              title={t('Modal_body--sureWantToDeactivate')}
              listTitle={t('Modal_listTitle--afterDeactivating')}
              list={getActivateJobPositionPros(t)}
              type="cons"
            />
          )}
          {modalStage === MAP_STAGES.UPGRADE && (
            <div>
              {t('Modal_body--noCreditsWantToUpgrade')}
              <div className={styles.changePlanAlertWrapper}>
                {!hasBillingPlanManagePermission ? <NoPermissions /> : null}
              </div>
            </div>
          )}
          {modalStage === MAP_STAGES.CHANGE_PLAN && (
            <div>
              {!isVerified && (
                <Alert
                  type="warning"
                  showIcon
                  className={styles.unverifiedAlert}
                >
                  <Trans i18nKey="Modal_body--orgAwaitsVerification">
                    We need to verify your organization, before changing the
                    plan.
                    <br />
                    Make sure you've send us all neccessary data in{' '}
                    <Link to="/settings">settings</Link> and we'll process it as
                    soon as possible.
                  </Trans>
                </Alert>
              )}
              {t('Modal_body--changePlanWithSlider')}
              <Slider
                defaultValue={monthlyCredits}
                value={newSubscriptionCredits}
                onChange={onSliderChange}
                max={MAX_SLIDER_VALUE}
                marks={{
                  [monthlyCredits]: '',
                }}
                disabled={!isVerified}
              />
              <div className={styles.changePlanToText}>
                <span>
                  <Trans
                    i18nKey="Modal_changePlan--changeToText"
                    count={newSubscriptionCredits}
                  >
                    To <b>{{ newSubscriptionCredits }}</b> open job positions /
                    mo
                  </Trans>
                </span>
                <span className={styles.changePlanToCost}>
                  {formatCurrency(
                    creditPrice * newSubscriptionCredits,
                    currency,
                  )}
                  {' / '}
                  {t('Modal_changePlan--perMo')}
                </span>
              </div>
              <span className={styles.changePlanToInfo}>
                {newSubscriptionCredits === monthlyCredits &&
                  t('Modal_changePlan--thisIsCurrentPlan')}
                {newSubscriptionCredits > monthlyCredits && (
                  <>
                    {t(
                      'You are increasing your plan by {{ count }} job positions per month',
                      { count: newSubscriptionCredits - monthlyCredits },
                    )}
                    {' (+'}
                    {formatCurrency(
                      (newSubscriptionCredits - monthlyCredits) * creditPrice,
                      currency,
                    )}
                    )
                  </>
                )}
                {newSubscriptionCredits < monthlyCredits && (
                  <>
                    {t(
                      'You are decreasing your plan by {{ count }} job positions per month',
                      { count: monthlyCredits - newSubscriptionCredits },
                    )}
                    {' (-'}
                    {formatCurrency(
                      (monthlyCredits - newSubscriptionCredits) * creditPrice,
                      currency,
                    )}
                    )
                  </>
                )}
              </span>
              {monthlyCredits - newSubscriptionCredits > availableCredits && (
                <div className={styles.changePlanAlertWrapper}>
                  <Alert type="warning">
                    <Trans
                      i18nKey="Modal_changePlanAlert--newPlan"
                      count={newSubscriptionCredits}
                    >
                      You are trying to change your plan to{' '}
                      <b>{{ newSubscriptionCredits }}</b> job positions / mo
                    </Trans>
                    {', '}
                    <Trans
                      i18nKey="Modal_changePlanAlert--openJobPositions"
                      count={monthlyCredits - availableCredits}
                    >
                      but you have{' '}
                      <b>{{ count: monthlyCredits - availableCredits }}</b> open
                      job positions
                    </Trans>
                    .
                    <div>
                      {t('Modal_changePlanAlert--toContinueCloseJobPositions')}
                    </div>
                  </Alert>
                </div>
              )}
            </div>
          )}
          {modalStage === MAP_STAGES.CONFIRM_CHANGE_PLAN && (
            <ProsAndConsList
              listTitle={t('Modal_listTitle--changingPlanWill')}
              list={getDecreasePlanCons(t, newSubscriptionCredits)}
              type="cons"
            />
          )}
          {modalStage === MAP_STAGES.THANK_YOU && (
            <div className={styles.infoContainer}>
              <CheckCircleOutlined
                className={cx(styles.infoIcon, styles.primary)}
              />
              <div className={styles.infoTitle}>
                {t('Modal_body--thankYou')}
              </div>
              <div className={styles.infoText}>
                {t('Modal_body--thankYouMessage')}
              </div>
            </div>
          )}
          {modalStage === MAP_STAGES.ERROR && (
            <div className={styles.infoContainer}>
              <ExclamationCircleOutlined
                className={cx(styles.infoIcon, styles.danger)}
              />
              <div className={styles.infoTitle}>{t('Modal_body--error')}</div>
              <div className={styles.infoText}>
                {t('Modal_body--errorMessage')}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader fullSize />
      )}
    </Modal>
  );
}

ChangePlanModal.propTypes = {
  isModalVisible: PropTypes.bool,
  jobPositionPk: PropTypes.string,
  billing: PropTypes.object,
  isVerified: PropTypes.bool,
  modalStage: PropTypes.string,
  newSubscriptionCredits: PropTypes.number,
  isInitialStageSet: PropTypes.bool,
  isNewSubscriptionCreditsSet: PropTypes.bool,
  isDataSaving: PropTypes.bool,
  onCancel: PropTypes.func,
  onSliderChange: PropTypes.func,
  onIsActiveToggle: PropTypes.func,
  onUpgrade: PropTypes.func,
  onPlanDecrease: PropTypes.func,
  onPlanIncrease: PropTypes.func,
  onConfirmChangePlan: PropTypes.func,
  onThankYou: PropTypes.func,
};

export default ChangePlanModal;
