import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import Loader from 'components/Loader';
import Header from 'pages/layout/Header';
import BrowserSupport from 'components/BrowserSupport';
import { SHOW_MENU_WIDTH } from 'consts/media';
import useThemeComponents from 'hooks/useThemeComponents';
import { COMPONENT_FOOTER } from 'consts/theme';
import SiderMenu from './SiderMenu';
import styles from './index.less';

const { Content: ContentWrapper, Footer } = Layout;

function BaseLayout({
  children,
  isMenuOpen,
  browserWidth,
  onMenuToggle,
  onMenuClick,
  organizations,
  isLoading,
}) {
  const [footer] = useThemeComponents(COMPONENT_FOOTER);

  if (isLoading) return <Loader fullSize />;

  return (
    <Layout className={styles.layout}>
      {browserWidth <= SHOW_MENU_WIDTH ? (
        <Drawer
          style={{ zIndex: 1000 }}
          open={isMenuOpen}
          onClose={onMenuToggle}
          handler={false}
        >
          <SiderMenu onMenuClick={onMenuClick} organizations={organizations} />
        </Drawer>
      ) : (
        <SiderMenu onMenuClick={onMenuClick} organizations={organizations} />
      )}
      <Layout>
        <Header
          onMenuClick={onMenuClick}
          isMenuOpen={isMenuOpen}
          browserWidth={browserWidth}
          showMenuHandler={browserWidth <= SHOW_MENU_WIDTH}
          onMenuToggle={onMenuToggle}
        />
        <BrowserSupport />
        <ContentWrapper className={styles.contentWrapper}>
          {children}
        </ContentWrapper>
        <Footer className={styles.footer}>
          {footer}
          Workate © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node,
  isMenuOpen: PropTypes.bool,
  browserWidth: PropTypes.number,
  onMenuToggle: PropTypes.func,
  onMenuClick: PropTypes.func,
  organizations: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default BaseLayout;
