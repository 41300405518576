import React, { useState, useEffect } from 'react';
import { RobotOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.less';

type LoaderSize = 'small' | 'default' | 'large';

type SizeConfig = {
  bot: number;
  containerClass: string;
};

type SizeMap = Record<LoaderSize, SizeConfig>;

type AILoaderProps = {
  /** Whether to display the loader in a full-width centered container */
  fullSize?: boolean;
  /** Size of the loader */
  size?: LoaderSize;
  /** Translation key for the message */
  messageKey?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function AILoader({
  fullSize = false,
  size = 'default',
  messageKey,
  ...rest
}: AILoaderProps): JSX.Element {
  const { t } = useTranslation();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const messageKeys = [
    'AILoader_message-analyzing',
    'AILoader_message-training',
    'AILoader_message-generating',
    'AILoader_message-applying',
    'AILoader_message-finalizing',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % messageKeys.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [messageKeys.length]);

  const sizeMap: SizeMap = {
    small: {
      bot: 16,
      containerClass: styles.smallLoaderContainer,
    },
    default: {
      bot: 24,
      containerClass: styles.defaultLoaderContainer,
    },
    large: {
      bot: 32,
      containerClass: styles.largeLoaderContainer,
    },
  };

  const currentSize = sizeMap[size];

  const loader = (
    <div className={styles.aiLoaderContainer} data-testid="ai-loader" {...rest}>
      <div className={classNames(styles.robotWrapper, styles.bouncing)}>
        <RobotOutlined
          className={styles.spinning}
          style={{ fontSize: currentSize.bot }}
        />
      </div>
      <div className={styles.message}>
        {messageKey ? t(messageKey) : t(messageKeys[currentMessageIndex])}
      </div>
    </div>
  );

  if (fullSize) {
    return (
      <div
        className={classNames(
          styles.fullSizeWrapper,
          currentSize.containerClass,
        )}
      >
        {loader}
      </div>
    );
  }

  return <div className={currentSize.containerClass}>{loader}</div>;
}

export default AILoader;
