import { REJECTION_STATUS } from 'consts/RejectionStatusEnum';
import {
  ROLE_GUEST,
  ROLE_RESTRICTED,
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_SUPERADMIN,
} from 'consts/roles';
import { HIRED_STAGE } from 'consts/stageList';

export function roleName(t, name) {
  return [
    ROLE_GUEST,
    ROLE_RESTRICTED,
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_SUPERADMIN,
  ].includes(name)
    ? t(`translation_roleName-${name}`, {
        defaultValue: name,
      })
    : name;
}

const rejectionStatusTranslations = {
  [REJECTION_STATUS.ALL]: 'CandidatesList_rejectionStatusFilter_all',
  [REJECTION_STATUS.REJECTED]: 'CandidatesList_rejectionStatusFilter_rejected',
  [REJECTION_STATUS.NON_REJECTED]:
    'CandidatesList_rejectionStatusFilter_nonRejected',
};

export const getRejectionStatusTranslation = (t, status) =>
  t(
    rejectionStatusTranslations[status] ||
      'CandidatesList_rejectionStatusFilter_unknown',
  );
export function stageName(t, name, withTranlsation) {
  if (name === HIRED_STAGE) {
    return t('StageList_hiredStage');
  }
  return withTranlsation ? t(name) : name;
}
