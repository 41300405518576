import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'i18n';
import Content from 'components/Content';
import Alert from 'components/Alert';
import Button from 'components/Form/Button';
import AILoader from 'components/AILoader';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import JobOfferForm from './JobOfferForm';
import styles from './JobOfferEdit.less';
import jobPositionDetailsQuery from '../JobPositionDetails/jobPositionDetailsQuery.gql';

const useJobOffer = (pk, offerPk) => {
  const [organizationPk] = useCurrentOrganization();
  const { data, loading, error, startPolling, stopPolling, ...rest } = useQuery(
    jobPositionDetailsQuery,
    {
      variables: { organizationPk, pk },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  const currentLandingPage =
    data?.viewer?.jobPositions?.edges[0]?.node?.landingPages?.find(
      (lp) => lp.pk === offerPk,
    );

  return {
    loading,
    error,
    currentLandingPage,
    startPolling,
    stopPolling,
    ...rest,
  };
};

function LoadingView({ children }) {
  return (
    <div className={styles.loadingContainer}>
      <AILoader size="large" messageKey={children} />
    </div>
  );
}

LoadingView.propTypes = {
  children: PropTypes.node.isRequired,
};

function ErrorView({ message, onBackClick }) {
  return (
    <div className={styles.errorContainer}>
      <Alert type="error" message={message} />
      <Button onClick={onBackClick} className={styles.backButton}>
        Back to Job Position
      </Button>
    </div>
  );
}

ErrorView.propTypes = {
  message: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

function JobOfferContent({
  currentLandingPage,
  onBackClick,
  startPolling,
  stopPolling,
  ...rest
}) {
  const { t } = useTranslation();
  const { aiGenerationStatus } = currentLandingPage;
  const isAIGenerated = Boolean(aiGenerationStatus);

  useEffect(() => {
    if (aiGenerationStatus === 'PROCESSING') {
      startPolling(5000);
    } else {
      stopPolling();
    }

    return () => stopPolling();
  }, [aiGenerationStatus, startPolling, stopPolling]);

  return (
    <div>
      {isAIGenerated && aiGenerationStatus === 'PROCESSING' && <LoadingView />}
      {isAIGenerated && aiGenerationStatus === 'ERROR' && (
        <ErrorView
          message={t('Error_aiGenerationFailed')}
          onBackClick={onBackClick}
        />
      )}
      {(!isAIGenerated || aiGenerationStatus === 'COMPLETED') && (
        <JobOfferForm
          landingPage={currentLandingPage}
          isAIGenerated={isAIGenerated}
          {...rest}
        />
      )}
    </div>
  );
}

JobOfferContent.propTypes = {
  currentLandingPage: PropTypes.shape({
    pk: PropTypes.string.isRequired,
    aiGenerationStatus: PropTypes.string,
  }).isRequired,
  onBackClick: PropTypes.func.isRequired,
  startPolling: PropTypes.func.isRequired,
  stopPolling: PropTypes.func.isRequired,
};

function JobOfferEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pk, offerPk } = useParams();
  const {
    loading,
    error,
    currentLandingPage,
    startPolling,
    stopPolling,
    ...rest
  } = useJobOffer(pk, offerPk);

  const handleBackClick = useCallback(() => {
    navigate(`/positions/${pk}`);
  }, [navigate, pk]);

  return (
    <Content title={t('Title_jobOfferEdit')} theme="transparent" size="medium">
      {loading && <LoadingView>{t('JobOfferEdit_loading')}</LoadingView>}
      {error && (
        <ErrorView
          message={t('Error_jobOfferEdit')}
          onBackClick={handleBackClick}
        />
      )}
      {!loading && !error && !currentLandingPage && (
        <ErrorView
          message={t('Error_landingPageNotFound')}
          onBackClick={handleBackClick}
        />
      )}
      {!loading && !error && currentLandingPage && (
        <JobOfferContent
          currentLandingPage={currentLandingPage}
          onBackClick={handleBackClick}
          startPolling={startPolling}
          stopPolling={stopPolling}
          {...rest}
        />
      )}
    </Content>
  );
}

export default JobOfferEdit;
