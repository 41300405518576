import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { withRouter } from 'utils/withRouter';
import withOrganizationPk from 'hoc/withOrganizationPk';
import { HomeOutlined, RollbackOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import Menu from 'components/Menu';
import { XS } from 'consts/media';
import Link from 'components/Link';
import Dropdown from 'components/Dropdown';
import useFocusEffect from 'hooks/useFocusEffect';
import { useLocation } from 'react-router';
import styles from './index.less';

const BREADCRUMB_MAP = {
  '/candidates': 'bread_candidates',
  '/teamMembers': 'bread_teamMembers',
  '/settings': 'bread_settings',
  '/settings/user-settings': 'bread_userSettings',
  '/settings/email-templates': 'bread_emailTemplates',
  '/positions': 'bread_jobPositions',
  '/positions/archived': 'bread_jobPositionsArchived',
  '/positions/add': 'bread_jobPositionAdd',
  '/reporting': 'bread_reporting',
  '/reporting/acquisition-report': 'bread_acquisitionReport',
  '/reporting/audience-report': 'bread_audienceReport',
  '/reporting/engagement-report': 'bread_engagementReport',
};

function Breadcrumbs({ organizationName, t, browserWidth }) {
  let dropdownTitle;
  const { pathname } = useLocation();
  const pathParts = pathname.split('/').filter((o) => o);
  const breadcrumbs = pathParts.map((part, i) => {
    const url = `/${pathParts.slice(0, i + 1).join('/')}`;
    const title = BREADCRUMB_MAP[url];
    const isLast = i === pathParts.length - 1;

    if (!title) {
      return false;
    }

    return (
      <Breadcrumb.Item key={url}>
        {isLast ? <span>{t(title)}</span> : <Link to={url}>{t(title)}</Link>}
      </Breadcrumb.Item>
    );
  });

  const pathItems = pathParts.map((part, i) => {
    const url = `/${pathParts.slice(0, i + 1).join('/')}`;
    const title = BREADCRUMB_MAP[url];

    if (!title) {
      return false;
    }

    dropdownTitle = t(title);
    const isDisabled = i === pathParts.length - 1;

    return {
      key: url,
      disabled: isDisabled,
      label: isDisabled ? (
        <span className={styles.menuItem}>{t(title)}</span>
      ) : (
        <Link to={url} className={styles.menuItem}>
          {t(title)}
        </Link>
      ),
    };
  });

  // state to manage dropdown visibility
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  // move focus to dropdown menu on dropdown open
  useFocusEffect(menuRef, isDropdownOpen);

  const items = [
    {
      key: 'home',
      label: (
        <Link to="/" className={styles.menuItem}>
          <HomeOutlined />
          &nbsp;
          {t('homePage')}
        </Link>
      ),
    },
    ...pathItems,
  ];
  const menu = <Menu selectedKeys={[]} items={items} ref={menuRef} />;

  return browserWidth > XS ? (
    <div className={styles.breadcrumbs} data-testid="breadcrumbs">
      <Breadcrumb>
        <Breadcrumb.Item key="home">
          <Link to="/">
            <HomeOutlined className={styles.homeIcon} aria-label="home icon" />{' '}
            {organizationName}
          </Link>
        </Breadcrumb.Item>
        {breadcrumbs.filter((o) => o)}
      </Breadcrumb>
    </div>
  ) : (
    <Dropdown
      overlay={menu}
      placement="bottomCenter"
      trigger={['hover', 'click']}
      open={isDropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <button type="button" className={styles.dropdown}>
        {dropdownTitle || (
          <>
            <HomeOutlined className={styles.dropdownHomeIcon} aria-hidden />
            {organizationName}
          </>
        )}
        &nbsp;
        {breadcrumbs[0] && <RollbackOutlined aria-label="go back" />}
      </button>
    </Dropdown>
  );
}

Breadcrumbs.propTypes = {
  t: PropTypes.func.isRequired,
  browserWidth: PropTypes.number,
  organizationName: PropTypes.string,
};

export default withRouter(
  withOrganizationPk(withTranslation('translation')(Breadcrumbs)),
);
