import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import { useTranslation } from 'i18n';
import { useNavigate } from 'react-router-dom';

import { FormOutlined } from '@ant-design/icons';

import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import { MANAGE_TEMPLATE } from './index';

import styles from './TemplateSelect.less';

function TemplateSelect({
  emailTemplatesList,
  onSelectEmailTemplate,
  isDataLoading,
  fullWidth,
  persistSelection,
  popupContainer,
  defaultTemplate,
}) {
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();
  const navigate = useNavigate();

  // Initialize selected value based on default template if provided (for rejection flow)
  const [selectedValue, setSelectedValue] = useState(() => {
    if (!defaultTemplate?.pk || !emailTemplatesList?.length) return null;
    const template = emailTemplatesList.find(
      ({ node }) => node.pk === defaultTemplate.pk,
    );
    return template?.node?.id || null;
  });

  const handleManageClick = useCallback(() => {
    navigate('/settings/email-templates');
  }, [navigate]);

  const onTemplateSelect = useCallback(
    (_, { value }) => {
      if (value === MANAGE_TEMPLATE) {
        handleManageClick();
        return;
      }

      setSelectedValue(value);

      const selectedTemplate = emailTemplatesList?.find(
        ({ node }) => node.id === value,
      );

      if (selectedTemplate && typeof onSelectEmailTemplate === 'function') {
        const { title, content, pk } = selectedTemplate.node;
        onSelectEmailTemplate(title, content, pk);
      }
    },
    [emailTemplatesList, onSelectEmailTemplate, handleManageClick],
  );

  const emailTemplatesChoices = useMemo(() => {
    const choices = (emailTemplatesList || []).map(({ node }) => ({
      value: node.id,
      label: node.title,
      optionElement: (
        <div>
          <div className={styles.templateTitle}>{node.title}</div>
          <div className={styles.templateText}>{node.content}</div>
        </div>
      ),
    }));

    choices.push({
      value: MANAGE_TEMPLATE,
      label: t('EmailTemplates_manage'),
      optionElement: (
        <div className={styles.manageLink}>{t('EmailTemplates_manage')}</div>
      ),
    });

    return choices;
  }, [emailTemplatesList, t]);

  return (
    <Select
      data-testid="email-templates-select"
      autoClearSearchValue
      value={persistSelection ? undefined : selectedValue}
      className={styles.select}
      choices={emailTemplatesChoices}
      dropdownMatchSelectWidth={browserWidth > XS ? 400 : undefined}
      placeholder={
        <>
          {' '}
          <FormOutlined className={styles.templateIcon} />
          {t('EmailTemplates')}
        </>
      }
      onSelect={onTemplateSelect}
      loading={isDataLoading}
      fullWidth={fullWidth}
      withSearch
      aria-label={t('EmailTemplates')}
      popupContainer={popupContainer}
    />
  );
}

TemplateSelect.propTypes = {
  isDataLoading: PropTypes.bool,
  emailTemplatesList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pk: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    }),
  ),
  onSelectEmailTemplate: PropTypes.func,
  fullWidth: PropTypes.bool,
  persistSelection: PropTypes.bool,
  popupContainer: PropTypes.object,
  defaultTemplate: PropTypes.shape({
    pk: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};

export default TemplateSelect;
