import React, { useCallback, useState } from 'react';
import { XS } from 'consts/media';
import Content from 'components/Content';
import Table from 'components/Table';
import Title from 'components/Title';
import { PERMISSION_ORGANIZATION_MANAGE } from 'consts/permissions.js';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';
import { message } from 'antd';

import MoreMenu from 'components/MoreMenu';

import useMembership from 'hooks/useMembership';
import Loader from 'components/Loader';
import useToggleState from 'hooks/useToggleState';
import { useTranslation } from 'react-i18next';
import { stageName } from 'utils/translation';
import { useMutation, useApolloClient } from '@apollo/client';
import { logAndShowGenericError } from 'utils/log';
import { DeleteOutlined } from '@ant-design/icons';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import styles from './StageListsSettings.less';
import useOrganizationStageLists from './useOrganizationStageLists';
import StageListAddModal from './StageListAddModal/StageListAddModal';

import DELETE_STAGE_LIST_MUTATION from './DeleteStageListMutation.gql';

const getMoreMenu = (t) => [
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('StageListSettings_delete'),
    dataRole: 'delete-stage-list',
  },
];

function StageListsSettings() {
  const [membership] = useMembership();
  const browserWidth = useBrowserWidth();
  const { t } = useTranslation();

  const client = useApolloClient();
  const organizationPk = membership ? membership.organization.pk : null;

  const [stageLists, { loading }] = useOrganizationStageLists({
    organizationPk,
  });

  const [isModalVisible, onModalOpen, onModalClose] = useToggleState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [hasOrganizationManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_ORGANIZATION_MANAGE]);

  const [deleteStageList] = useMutation(DELETE_STAGE_LIST_MUTATION);

  const handleDelete = useCallback(
    async (stageListPk) => {
      setIsSaving(true);
      try {
        const result = await deleteStageList({
          variables: { pk: stageListPk, organizationPk },
        });

        const deleteSuccess = result.data?.deleteStageList?.success;
        const errorMessage = result.data?.deleteStageList?.error;

        if (deleteSuccess) {
          message.success(t('StageListSettings_message-stageListDeleted'));

          // Generate the cache identifier for the Stage List that needs to be removed
          const identifyStageList = client.cache.identify({
            __typename: 'StageListNode',
            pk: stageListPk,
          });

          // Evict the deleted Stage List from Apollo cache
          client.cache.evict({
            id: identifyStageList,
          });

          // Perform garbage collection to clean up unused data in Apollo cache after eviction
          client.cache.gc();
        } else {
          message.error(t(errorMessage));
        }
      } catch (error) {
        logAndShowGenericError('Error deleting stage list:', error);
      } finally {
        setIsSaving(false);
      }
    },
    [deleteStageList, organizationPk, t, client],
  );

  const onItemClick = useCallback(
    (stageList) => {
      if (isSaving) return;

      confirmDeleteModal(
        t('StageListSettings_sureWantToDelete', { name: stageList.name }),
        () => handleDelete(stageList.key),
      );
    },
    [handleDelete, t, isSaving],
  );

  const COLUMNS = [
    {
      title: t('StageListsSettings_thName'),
      dataIndex: 'name',
      key: 'name',
      className: styles.listName,
    },
    {
      title: t('StageListsSettings_thStages'),
      dataIndex: 'stages',
      key: 'stages',
      className: styles.listName,
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (_, stageList) => (
        <MoreMenu
          id={stageList.pk}
          items={getMoreMenu(t)}
          onItemClick={
            hasOrganizationManagePermission
              ? () => onItemClick(stageList)
              : showNoPermissionMessage
          }
          menuTitle={stageList.name}
        />
      ),
    },
  ];

  if (loading) return <Loader fullSize />;

  return (
    <Content
      title={t('StageListsSettings_title')}
      secondary
      size="small"
      condensed
      withHeaderSpacing
      CTAText={t('StageListsSettings_addStageList')}
      onCTAClick={
        hasOrganizationManagePermission ? onModalOpen : showNoPermissionMessage
      }
    >
      <Title>Title_stageListsSettings</Title>
      <Table
        columns={COLUMNS}
        dataSource={stageLists.map((stageList) => ({
          key: stageList.pk,
          name: stageList.name,
          stages: stageList.stages
            .map((stage) => stageName(t, stage.name))
            .join(' > '),
        }))}
        pagination={{
          hideOnSinglePage: true,
        }}
        showHeader={browserWidth > XS}
        className={styles.table}
      />
      {isModalVisible && (
        <StageListAddModal isVisible={isModalVisible} onFinish={onModalClose} />
      )}
    </Content>
  );
}

export default StageListsSettings;
