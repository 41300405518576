import React, { memo, useCallback } from 'react';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import changeNotificationStatusMutation from 'hooks/useNotificationStatusChange/changeNotificationStatusMutation.gql';
import { NOTIFICATION_STATUS } from 'pages/notifications/NotificationsScreen/Notification';
import { useTranslation } from 'i18n';

import { BellOutlined } from '@ant-design/icons';

function ReadAllNotificationButton({ unreadNotifications }) {
  const [changeNotificationStatus] = useMutation(
    changeNotificationStatusMutation,
  );
  const { t } = useTranslation();

  const handleReadAllClick = useCallback(() => {
    const unreadNotificationsPks = unreadNotifications.map(
      ({ node }) => node.pk,
    );
    const status = NOTIFICATION_STATUS.READ;

    changeNotificationStatus({
      variables: { notificationsPks: unreadNotificationsPks, status },
      optimisticResponse: {
        notificationStatusChange: {
          errors: null,
          notifications: unreadNotificationsPks.map((pk) => ({
            id: pk,
            status,
            __typename: 'NotificationNode',
          })),
          __typename: 'ChangeNotificationStatusMutation',
        },
      },
    });
  }, [changeNotificationStatus, unreadNotifications]);

  return (
    <Button
      onClick={(e) => {
        handleReadAllClick();
        // After clicking, the focus remains, we have to remove it
        e.currentTarget.blur();
      }}
      size="default"
      icon={<BellOutlined />}
      data-testid="read-all-notification-button"
    >
      {t('ReadAllNotificationButton-notification-button')}
    </Button>
  );
}

ReadAllNotificationButton.propTypes = {
  unreadNotifications: PropTypes.array,
};

export default memo(ReadAllNotificationButton);
