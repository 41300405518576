import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import { useTranslation } from 'i18n';
import {
  MailOutlined,
  LoadingOutlined,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';

import { SM } from 'consts/media';
import { PERMISSION_CANDIDATE_MANAGE } from 'consts/permissions';
import { FIELD_TYPE_MULTICHOICE } from 'consts/fieldTypes';
import Button from 'components/Form/Button';
import CandidateDrawer from 'pages/candidates/CandidateDrawer';
import Search from 'components/Search';
import Content from 'components/Content';
import CustomFilters from 'components/CustomFilters';
import Title from 'components/Title';
import { PLAN_TYPES } from 'consts/planTypes';
import { REJECTION_STATUS } from 'consts/RejectionStatusEnum';
import {
  MODULE_CANDIDATE_ADD,
  MODULE_PLAN_PAGE_VIEW,
  MODULE_BULK_EMAIL_SEND,
} from 'consts/organizationModules';
import useBrowserWidth from 'hooks/useBrowserWidth';
import useOrganizationModules from 'hooks/useOrganizationModules';
import usePermissions from 'hooks/usePermissions';
import useToggleState from 'hooks/useToggleState';

import { getRejectionStatusTranslation, stageName } from 'utils/translation';
import CandidatesTable from './CandidatesTable';
import HiddenApplicationsAlert from './HiddenApplicationsAlert';
import FreePlanAlert from './FreePlanAlert';
import SendBulkEmailModal from './SendBulkEmailModal';

import styles from './CandidatesList.less';

const sortJobPositions = (jobPositions, t) => {
  const active = [];
  const archived = [];

  jobPositions.forEach(({ node }) => {
    if (node.isActive) {
      active.push({ text: node.position, value: node.pk });
    } else {
      archived.push({ text: node.position, value: node.pk });
    }
  });

  return [
    { name: t('active'), list: active },
    { name: t('archived'), list: archived },
  ];
};

function CandidatesList({
  q,
  filters,
  navigate,
  isAllCandidatesLoading,
  allStageLists,
  allCandidates,
  candidatesCount,
  billing,
  quota,
  customFields,
  onNewCandidateFormOpen,
  onNewCandidateCreated,
  isFetchMoreLoading,
  onLoadMoreCandidates,
  hasNextPage,
  isSearchLoading,
  relatedUsers,
  jobPositions,
  ratingScales,
  onSearchInputChange,
  onFiltersChange,
  onClearAllFilters,
  isDrawerVisible,
  activeApplicationPk,
  setActiveApplication,
  selectedCandidatePk,
  onDrawerClose,
  onCandidateSelected,
  onApplicationClick,
  onCandidateDeleted,
}) {
  const browserWidth = useBrowserWidth();
  const { t } = useTranslation();
  const [hasCandidateManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_CANDIDATE_MANAGE]);

  const [isModalVisible, onModalShow, onModalHide] = useToggleState(false);
  const [areFiltersShown, onFiltersShow, onFiltersHide] = useToggleState(false);

  const [hasCandidateAddModule, hasPlanPageViewModule, hasBulkEmailingModule] =
    useOrganizationModules([
      MODULE_CANDIDATE_ADD,
      MODULE_PLAN_PAGE_VIEW,
      MODULE_BULK_EMAIL_SEND,
    ]);

  const filterSets = useMemo(() => {
    // Create an array of selected job positions pk's
    const selectedJobPositions = jobPositions.filter((jobPosition) =>
      filters.jobPositionFilter?.includes(jobPosition.node.pk),
    );
    // Create an array of stage lists pk's of selected job positions
    const selectedJobPositionStageListsPk = selectedJobPositions.map(
      (jobPosition) => jobPosition.node.stages.pk,
    );
    const selectedStagesLists = allStageLists.filter((list) =>
      selectedJobPositionStageListsPk.includes(list.pk),
    );

    const stages =
      selectedStagesLists.length > 0 ? selectedStagesLists : allStageLists;

    return [
      {
        category: t('CandidatesList_filterTitle--relatedUsers'),
        categoryId: 'relatedUserFilter',
        list: relatedUsers?.map((o) => ({
          text: `${o.firstName} ${o.lastName}`,
          value: o.pk,
        })),
      },
      {
        category: t('CandidatesList_filterTitle--jobPositions'),
        categoryId: 'jobPositionFilter',
        list: sortJobPositions(jobPositions, t),
      },
      {
        category: t('CandidatesList_filterTitle--stages'),
        categoryId: 'stageFilter',
        list: stages.map((o) => ({
          name: o.name,
          list: o.stages.map(({ pk, name }) => ({
            text: stageName(t, name),
            value: pk,
          })),
        })),
      },
      {
        category: t('CandidatesList_filterTitle--rating'),
        categoryId: 'ratingFilter',
        list: [
          { value: 'None', label: 'rating_none' },
          ...ratingScales.map((o) => ({
            name: o.name,
            list: o.options.map(({ pk, value }) => ({
              text: value,
              value: pk,
            })),
          })),
        ],
      },
      {
        category: t('CandidatesList_filterTitle--hasCv'),
        categoryId: 'hasCvFilter',
        list: [
          {
            text: t('CandidatesList_hasCvFilter_true'),
            value: 'true',
          },
          {
            text: t('CandidatesList_hasCvFilter_false'),
            value: 'false',
          },
        ],
      },
      {
        category: t('CandidatesList_filterTitle--rejectionStatus'),
        categoryId: 'rejectionStatusFilter',
        list: [
          {
            value: REJECTION_STATUS.ALL,
            text: getRejectionStatusTranslation(t, REJECTION_STATUS.ALL),
          },
          {
            value: REJECTION_STATUS.REJECTED,
            text: getRejectionStatusTranslation(t, REJECTION_STATUS.REJECTED),
          },
          {
            value: REJECTION_STATUS.NON_REJECTED,
            text: getRejectionStatusTranslation(
              t,
              REJECTION_STATUS.NON_REJECTED,
            ),
          },
        ],
      },
      ...customFields.map((field) => {
        // separate text custom fields from multichoice custom fields
        if (field.fieldType === FIELD_TYPE_MULTICHOICE) {
          return {
            category: field.name,
            categoryId: `customFieldMultichoiceFilter_${field.name}`,
            fieldType: field.fieldType,
            list: field.choices?.map(({ value }) => ({ value, text: value })),
          };
        }
        return {
          category: field.name,
          categoryId: `customFieldFilter_${field.name}`,
          fieldType: field.fieldType,
          list: [],
        };
      }),
    ];
  }, [
    jobPositions,
    allStageLists,
    t,
    relatedUsers,
    ratingScales,
    customFields,
    filters.jobPositionFilter,
  ]);

  const dataSource = useMemo(
    () =>
      allCandidates.map(({ node }) => ({
        key: node.id,
        ...node,
        t,
        navigate,
        candidatePk: node.pk,
        onApplicationClick,
        onCandidateSelected,
        browserWidth,
        isRejected: node.applications.edges.some(
          (edges) => edges.node.isRejected,
        ),
      })),
    [
      allCandidates,
      t,
      navigate,
      onApplicationClick,
      browserWidth,
      onCandidateSelected,
    ],
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: [selectedCandidatePk],
      columnWidth: '0',
    }),
    [selectedCandidatePk],
  );

  const { planType } = billing;

  const handleClick = hasCandidateManagePermission
    ? onNewCandidateFormOpen
    : showNoPermissionMessage;

  return (
    <Content
      condensed
      title={t('CandidatesList_header')}
      CTAText={t('CandidatesList_addCandidate')}
      onCTAClick={hasCandidateAddModule ? handleClick : null}
      CTADisabled={
        hasPlanPageViewModule &&
        planType === PLAN_TYPES.FREE &&
        quota.candidate.isExceeded
      }
      secondaryButton={
        hasBulkEmailingModule && (
          <Button
            size="default"
            icon={<MailOutlined aria-hidden />}
            className={styles.buttonSecondary}
            onClick={onModalShow}
          >
            {browserWidth >= SM && t('CandidatesList_bulkEmailButton')}
          </Button>
        )
      }
      subtitle={
        <>
          <Title>{t('Title_candidates')}</Title>
          <div className={styles.searchFilterBar}>
            <Search
              onSearch={() => null}
              placeholder={t('searchCandidatePlaceholder')}
              popupLabel
              labelDark
              className={styles.searchInput}
              size="default"
              value={q}
              onChange={onSearchInputChange}
            />
            {areFiltersShown ? (
              <Button
                size="small"
                type="text"
                onClick={onFiltersHide}
                className={styles.hideFiltersButton}
                aria-label="hide filters"
              >
                {t('CustomFilters-hideFilters')}
                <UpOutlined aria-hidden />
              </Button>
            ) : (
              <Button
                size="small"
                type="text"
                onClick={onFiltersShow}
                className={styles.hideFiltersButton}
                aria-label="advanced filters"
              >
                {t('CustomFilters-advancedFilters')}
                <DownOutlined aria-hidden />
              </Button>
            )}
          </div>
          <CustomFilters
            areFiltersShown={areFiltersShown}
            filterSets={filterSets}
            onChange={onFiltersChange}
            values={filters}
            onClearAllFilters={onClearAllFilters}
            relatedUsers={relatedUsers}
            jobPositions={jobPositions}
            allStageLists={allStageLists}
            ratingScales={ratingScales}
          />
          <div className={styles.candidatesCount}>
            {t('totalCandidatesCount')}
            {': '}
            {isAllCandidatesLoading ? (
              <LoadingOutlined />
            ) : (
              <b data-testid="candidates-count">{candidatesCount}</b>
            )}
          </div>
          <HiddenApplicationsAlert />
          {hasPlanPageViewModule && <FreePlanAlert />}
        </>
      }
    >
      <CandidatesTable
        loading={isAllCandidatesLoading || isSearchLoading}
        rowSelection={rowSelection}
        dataSource={dataSource}
      />
      {hasNextPage && (
        <div className={styles.fetchMoreLoading}>
          <Button
            type="default"
            onClick={onLoadMoreCandidates}
            disabled={isFetchMoreLoading}
            size="default"
          >
            {isFetchMoreLoading ? <LoadingOutlined /> : t('loadMoreCandidates')}
          </Button>
        </div>
      )}
      <CandidateDrawer
        visible={isDrawerVisible}
        onDrawerClose={onDrawerClose}
        selectedCandidatePk={selectedCandidatePk}
        activeApplicationPk={activeApplicationPk}
        setActiveApplication={setActiveApplication}
        onNewCandidateCreated={onNewCandidateCreated}
        onCandidateDeleted={onCandidateDeleted}
      />
      {isModalVisible && (
        <SendBulkEmailModal visible={isModalVisible} onHide={onModalHide} />
      )}
    </Content>
  );
}

CandidatesList.propTypes = {
  filters: PropTypes.object,
  q: PropTypes.string,
  isAllCandidatesLoading: PropTypes.bool.isRequired,
  allStageLists: PropTypes.array.isRequired,
  allCandidates: PropTypes.array.isRequired,
  ratingScales: PropTypes.array.isRequired,
  candidatesCount: PropTypes.number.isRequired,
  billing: PropTypes.object.isRequired,
  quota: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  onNewCandidateFormOpen: PropTypes.func.isRequired,
  onNewCandidateCreated: PropTypes.func.isRequired,
  onCandidateDeleted: PropTypes.func.isRequired,
  isFetchMoreLoading: PropTypes.bool,
  onLoadMoreCandidates: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  relatedUsers: PropTypes.array.isRequired,
  jobPositions: PropTypes.array.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onClearAllFilters: PropTypes.func.isRequired,
  isDrawerVisible: PropTypes.bool,
  activeApplicationPk: PropTypes.string,
  setActiveApplication: PropTypes.func,
  selectedCandidatePk: PropTypes.string,
  onDrawerClose: PropTypes.func,
  onCandidateSelected: PropTypes.func,
  onApplicationClick: PropTypes.func,
  // From HoCs
  navigate: PropTypes.func.isRequired,
};

export default memo(withRouter(CandidatesList));
