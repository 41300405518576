import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';

function RejectionReasonFormModal({
  isVisible,
  onCancel,
  onSave,
  title,
  initialName,
  fieldErrors,
  clearFieldError,
  generalError,
  isSaving,
}) {
  const { t } = useTranslation();
  const [reasonName, setReasonName] = useState(initialName);

  const onNameInputChange = useCallback(
    (e) => {
      setReasonName(e.target.value);
      clearFieldError('name');
    },
    [setReasonName, clearFieldError],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSave(reasonName);
    },
    [onSave, reasonName],
  );

  return (
    <Modal
      open={isVisible}
      maskClosable={false}
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
      okDisabled={isSaving}
      okText={t('RejectionReasonFormModal_okText')}
    >
      {generalError && <Alert type="error" showIcon message={generalError} />}
      <Form layout="vertical" onFinish={onSubmit}>
        <Field
          label={t('RejectionReasonFormModal_fieldName')}
          error={fieldErrors.name}
          colon={false}
        >
          <Input
            type="text"
            value={reasonName}
            onChange={onNameInputChange}
            placeholder={t('RejectionReasonFormModal_example')}
            autoFocus
          />
        </Field>
      </Form>
    </Modal>
  );
}

RejectionReasonFormModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialName: PropTypes.string,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  generalError: PropTypes.string,
  isSaving: PropTypes.bool,
};

export default RejectionReasonFormModal;
