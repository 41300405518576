import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { graphql } from 'apollo';
import {
  LogoutOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import { withTranslation } from 'i18n';

import { MD } from 'consts/media';
import onEnter from 'utils/onEnter';
import Avatar from 'components/Avatar';
import ArrowIcon from 'components/ArrowIcon';
import Menu from 'components/Menu';
import Dropdown from 'components/Dropdown';

import useFocusEffect from 'hooks/useFocusEffect';
import Breadcrumbs from '../../../components/Breadcrumbs';
import styles from './index.less';
import currentUserQuery from './currentUser.gql';

const { Header: AntHeader } = Layout;

function Header({
  t,
  currentUser,
  className,
  onMenuClick,
  isMenuOpen,
  browserWidth,
  showMenuHandler,
  onMenuToggle,
}) {
  // state to manage dropdown visibility
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const items = [
    {
      key: 'settings',
      label: t('settings'),
      icon: <SettingOutlined aria-hidden />,
      onClick: onMenuClick,
    },
    {
      key: 'logout',
      label: t('logout'),
      icon: <LogoutOutlined aria-hidden />,
      onClick: onMenuClick,
    },
  ];
  const menu = <Menu selectedKeys={[]} items={items} ref={menuRef} />;

  // move focus to dropdown menu on dropdown open
  useFocusEffect(menuRef, isDropdownOpen);

  return (
    <AntHeader className={cx(styles.header, className)}>
      {showMenuHandler && (
        <>
          <div
            className={styles.menuTrigger}
            onClick={onMenuToggle}
            role="menu"
            tabIndex="0"
            onKeyPress={onEnter(onMenuToggle)}
            data-testid="menu-trigger"
          >
            {!isMenuOpen && (
              <MenuUnfoldOutlined
                className={styles.menuTriggerIcon}
                data-testid="icon-menuUnfold"
              />
            )}
            {isMenuOpen && (
              <MenuFoldOutlined
                className={styles.menuTriggerIcon}
                data-testid="icon-menuFold"
              />
            )}
            <span className={styles.menuTriggerText}>{t('menuTrigger')}</span>
          </div>
          <div className={styles.menuTriggerDivider} />
        </>
      )}

      <Breadcrumbs browserWidth={browserWidth} onMenuClick={onMenuClick} />
      {currentUser && (
        <Dropdown
          overlay={menu}
          trigger={['click']}
          open={isDropdownOpen}
          onOpenChange={setDropdownOpen}
        >
          <button type="button" className={styles.userMenu}>
            <span className={styles.loginMenu}>
              <Avatar
                initials={currentUser.initials}
                email={currentUser.email}
              />
              {browserWidth > MD &&
                (currentUser.firstName || currentUser.lastName) && (
                  <span className={styles.userName}>
                    {currentUser.firstName} {currentUser.lastName}
                  </span>
                )}
              <ArrowIcon className={styles.arrow} />
            </span>
          </button>
        </Dropdown>
      )}
      {/* {currentUser && <NotificationsCounter />} temporarily hidden because of bad performance */}
    </AntHeader>
  );
}

Header.propTypes = {
  t: PropTypes.func.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentUser: PropTypes.object,
  isMenuOpen: PropTypes.bool,
  showMenuHandler: PropTypes.bool,
  browserWidth: PropTypes.number,
  onMenuToggle: PropTypes.func,
};

export default withTranslation('translation')(
  graphql(currentUserQuery, {
    props: ({ data: { viewer, loading } }) => ({
      currentUser: viewer && viewer.currentUser,
      isLoading: loading,
    }),
  })(Header),
);
