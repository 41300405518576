import createCustomFieldFilterObject from 'utils/createCustomFieldFilterObject';
import createCustomFieldSelectedChoicesFilterObject from 'utils/createCustomFieldSelectedChoicesFilterObject';
import { stageName } from 'utils/translation';
import getFiltersFromUrl from './getFiltersFromUrl';
import { getRejectionStatusTranslation } from './translation';

const createActiveFiltersArray = ({
  location,
  relatedUsers,
  jobPositions,
  allStageLists,
  ratingScales,
  t,
}) => {
  /*
    Helper function, use to create active filters array,
    to use it for creating active filters tags (ActiveFilterTag)
    The array consists from objects like e.g:
    { key: 'job position', values: ['Developer', 'Tester]}
  */
  const {
    relatedUserFilter,
    jobPositionFilter,
    stageFilter,
    ratingFilter,
    hasCvFilter,
    customFieldsFilters,
    customFieldsSelectedChoicesFilters,
    rejectionStatusFilter,
  } = getFiltersFromUrl(location);

  const allOptions = ratingScales?.flatMap((scale) => scale.options);

  const activeFiltersArray = [
    {
      key: t('ActiveFilters_relatedUser'),
      name: 'relatedUserFilter',
      values: relatedUserFilter?.map((relatedUserPk) => {
        const foundRelatedUser = relatedUsers.find(
          (relatedUser) => relatedUser.pk === relatedUserPk,
        );
        return foundRelatedUser ? foundRelatedUser.firstName : undefined;
      }),
    },
    {
      key: t('ActiveFilters_jobPosition'),
      name: 'jobPositionFilter',
      values: jobPositionFilter?.map((jobPositionPk) => {
        const foundPosition = jobPositions.find(
          (jobPositionEdge) => jobPositionEdge.node.pk === jobPositionPk,
        );
        return foundPosition ? foundPosition.node.position : undefined;
      }),
    },
    {
      key: t('ActiveFilters_rating'),
      name: 'ratingFilter',
      values: ratingFilter?.map((filter) => {
        const ratingMapping = allOptions
          .filter((option) => option?.pk === filter)
          .flatMap((option) => option.value);
        return ratingMapping.length > 0 ? ratingMapping : filter;
      }),
    },
    {
      key: t('ActiveFilters_stage'),
      name: 'stageFilter',
      values: stageFilter?.map((stagePk) => {
        // Use flatMap to flatten the stages arrays and then use find to get the matched stage
        const matchedStage = allStageLists
          .flatMap((stageList) => stageList.stages)
          .find((stage) => stage.pk === stagePk);
        if (!matchedStage) {
          return;
        }
        return stageName(t, matchedStage.name);
      }),
    },
    {
      key: 'hasCv',
      name: 'hasCvFilter',
      values: hasCvFilter !== undefined ? [hasCvFilter.toString()] : undefined,
    },
    {
      key: t('ActiveFilters_rejectionStatus'),
      name: 'rejectionStatusFilter',
      values: rejectionStatusFilter
        ? [getRejectionStatusTranslation(t, rejectionStatusFilter)]
        : [],
    },

    ...createCustomFieldFilterObject(customFieldsFilters).map((filter) => ({
      key: filter.customField,
      name: `customFieldFilter_${filter.customField}`,
      values: filter.values,
    })),
    ...createCustomFieldSelectedChoicesFilterObject(
      customFieldsSelectedChoicesFilters,
    ).map((filter) => ({
      key: filter.choiceField,
      name: `customFieldMultichoiceFilter_${filter.choiceField}`,
      values: filter.values,
    })),
  ];

  return activeFiltersArray;
};

export default createActiveFiltersArray;
