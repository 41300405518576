import storage from 'utils/storage';
import log from 'utils/log';

const EMAIL_PREFS_KEY = 'emailPreferences';

interface TemplateMap {
  [key: string]: string;
}

interface OrganizationPreferences {
  emailEnabled: boolean;
  rejectionTemplates: TemplateMap;
  stageTemplates: TemplateMap;
  stageEmailEnabled: boolean;
}

interface AllPreferences {
  [organizationPk: string]: OrganizationPreferences;
}

const getDefaultPreferences = (): OrganizationPreferences => ({
  emailEnabled: true,
  rejectionTemplates: {},
  stageTemplates: {},
  stageEmailEnabled: true,
});

const readPreferences = (): AllPreferences => {
  try {
    const prefs = storage.get(EMAIL_PREFS_KEY);
    return typeof prefs === 'string' ? JSON.parse(prefs) : {};
  } catch (error) {
    log('Failed to parse email preferences:', error);
    return {};
  }
};

const writePreferences = (preferences: AllPreferences): void => {
  try {
    storage.persist({ [EMAIL_PREFS_KEY]: JSON.stringify(preferences) });
  } catch (error) {
    log('Failed to save email preferences:', error);
  }
};

export const getEmailEnabled = (organizationPk: string): boolean => {
  try {
    const prefs = readPreferences();
    return prefs[organizationPk]?.emailEnabled ?? true;
  } catch (error) {
    log('Failed to get email enabled state:', error);
    return true;
  }
};

export const setEmailEnabled = (
  organizationPk: string,
  enabled: boolean,
): void => {
  try {
    const prefs = readPreferences();
    prefs[organizationPk] = {
      ...getDefaultPreferences(),
      ...prefs[organizationPk],
      emailEnabled: enabled,
    };
    writePreferences(prefs);
  } catch (error) {
    log('Failed to save email enabled state:', error);
  }
};

export const getRejectionTemplate = (
  organizationPk: string,
  reasonPk: string,
): string | null => {
  try {
    const prefs = readPreferences();
    return prefs[organizationPk]?.rejectionTemplates[reasonPk] ?? null;
  } catch (error) {
    log('Failed to get rejection template:', error);
    return null;
  }
};

export const setRejectionTemplate = (
  organizationPk: string,
  reasonPk: string,
  templatePk: string,
): void => {
  try {
    const prefs = readPreferences();
    prefs[organizationPk] = {
      ...getDefaultPreferences(),
      ...prefs[organizationPk],
      rejectionTemplates: {
        ...prefs[organizationPk]?.rejectionTemplates,
        [reasonPk]: templatePk,
      },
    };
    writePreferences(prefs);
  } catch (error) {
    log('Failed to save rejection template:', error);
  }
};

export const getStageEmailEnabled = (organizationPk: string): boolean => {
  try {
    const prefs = readPreferences();
    return prefs[organizationPk]?.stageEmailEnabled ?? true;
  } catch (error) {
    log('Failed to get stage email enabled state:', error);
    return true;
  }
};

export const setStageEmailEnabled = (
  organizationPk: string,
  enabled: boolean,
): void => {
  try {
    const prefs = readPreferences();
    prefs[organizationPk] = {
      ...getDefaultPreferences(),
      ...prefs[organizationPk],
      stageEmailEnabled: enabled,
    };
    writePreferences(prefs);
  } catch (error) {
    log('Failed to save stage email enabled state:', error);
  }
};

export const getStageTemplate = (
  organizationPk: string,
  stagePk: string,
): string | null => {
  try {
    const prefs = readPreferences();
    return prefs[organizationPk]?.stageTemplates[stagePk] ?? null;
  } catch (error) {
    log('Failed to get stage template:', error);
    return null;
  }
};

export const setStageTemplate = (
  organizationPk: string,
  stagePk: string,
  templatePk: string,
): void => {
  try {
    const prefs = readPreferences();
    prefs[organizationPk] = {
      ...getDefaultPreferences(),
      ...prefs[organizationPk],
      stageTemplates: {
        ...prefs[organizationPk]?.stageTemplates,
        [stagePk]: templatePk,
      },
    };
    writePreferences(prefs);
  } catch (error) {
    log('Failed to save stage template:', error);
  }
};
